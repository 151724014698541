import { FC } from "react";

type Props = {
  name: string;
  hidden?: string;
};

const MobileTimePicker: FC<Props> = ({ name, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-[60px] flex-col justify-center border-b bg-white">
      <p className="px-5 text-[11px] font-semibold uppercase text-[#636366]">
        {name}
      </p>
      <input
        type="text"
        disabled
        className="border-none px-5 py-0 text-[15px] font-medium text-black placeholder:font-normal placeholder:text-[#c7c7cb]"
        placeholder="HH:MM"
      />
    </div>
  );
};

export { MobileTimePicker };
