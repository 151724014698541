import { CreateUserPage } from "./pages/CreateUser.tsx";
import { FC } from "react";
import { LoadingSpinner } from "./UI/Loading/LoadingSpinner.tsx";
import { AdminPricebookPage } from "./pages/admin/AdminPricebookPage.tsx";
import { Route, Routes } from "react-router-dom";
import { useAuthContext } from "./components/Auth/AuthWrapper.tsx";
import { UserDetailsPage } from "./pages/UserDetails.tsx";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AdminAccountFormDetail from "./pages/admin/forms/AdminAccountFormDetail.tsx";
import AdminAccountFormsPage from "./pages/admin/forms/AdminAccountFormsPage.tsx";
import AdminAccountPage from "./pages/admin/AdminAccountPage.tsx";
import AdminAssetHistoryPage from "./pages/admin/AdminAssetHistoryPage.tsx";
import AdminBranchPage from "./pages/admin/AdminBranchPage.tsx";
import AdminCreateBranchPage from "./pages/admin/AdminCreateBranch.tsx";
import AdminCreateDivisionPage from "./pages/admin/AdminCreateDivisionPage.tsx";
import CreateQuotePage from "./pages/CreateQuotePage.tsx";
import AdminDivisionPage from "./pages/admin/AdminDivisionPage.tsx";
import AdminEditRawFormPage from "./pages/admin/forms/AdminEditRawFormPage.tsx";
import JobDetailPage from "./pages/JobDetail.tsx";
import JobDocuments from "./pages/JobDocuments.tsx";
import JobPage from "./pages/JobPage.tsx";
import LoginPage from "./pages/Login";
import Page404 from "./pages/404";
import ProfilePage from "./pages/ProfilePage.tsx";
import QuoteDetailsPage from "./pages/QuoteDetailsPage.tsx";
import QuotingPage from "./pages/QuotingPage.tsx";
import AdminAddUserPage from "./pages/admin/AdminAddUserPage.tsx";
import AdminCreateFormPage from "./pages/admin/forms/AdminCreateFormPage.tsx";
import AdminUserDetailsPage from "./pages/admin/AdminUserDetailsPage.tsx";
import UsersPage from "./pages/Users.tsx";
import AssetOverviewPage from "./pages/AssetOverviewPage.tsx";
import { AssetDocuments } from "./pages/AssetDocuments.tsx";
import { AssetImagesPage } from "./pages/AssetImagesPage.tsx";
import { SettingsPage } from "./pages/settings/SettingsPage.tsx";
import { SettingsPricebookPage } from "./pages/settings/SettingsPricebookPage.tsx";
import { SettingsFormManagerPage } from "./pages/settings/SettingsFormManagerPage.tsx";
import { AdminAIFormBuilderPage } from "./pages/admin/forms/AdminAIFormBuilderPage.tsx";
import lazyWithRetry from "./utils/helpers/lazyWithRetry.ts";

const AssetDetailPage = lazyWithRetry(() => import("./pages/AssetDetail"));
const AssetsBrowserPage = lazyWithRetry(
  () => import("./pages/AssetsBrowser.tsx")
);
const AssetsSearchPage = lazyWithRetry(
  () => import("./pages/AssetsSearch.tsx")
);
const AdminAssetsPage = lazyWithRetry(
  () => import("./pages/admin/AdminAssetsPage.tsx")
);
const CreateAssetPage = lazyWithRetry(() => import("./pages/CreateAsset"));
const DashboardPage = lazyWithRetry(() => import("./pages/Dashboard.tsx"));
const DocumentDetailsPage = lazyWithRetry(
  () => import("./pages/DocumentDetailsPage.tsx")
);
const DocumentsPage = lazyWithRetry(() => import("./pages/DocumentsPage.tsx"));
const AdminEditCSVConfig = lazyWithRetry(
  () => import("./pages/admin/forms/AdminEditCSVConfig.tsx")
);
const AdminEditDynamicDocument = lazyWithRetry(
  () => import("./pages/admin/forms/AdminEditDynamicDocument.tsx")
);
const AdminEditMobileLayout = lazyWithRetry(
  () => import("./pages/admin/forms/AdminEditMobileLayout.tsx")
);
const AdminEditPDFConfig = lazyWithRetry(
  () => import("./pages/admin/forms/AdminEditPDFConfig.tsx")
);
const AdminEditWebLayout = lazyWithRetry(
  () => import("./pages/admin/forms/AdminEditWebLayout.tsx")
);
const IconsPage = lazyWithRetry(() => import("./pages/IconsPage.tsx"));
const MapPage = lazyWithRetry(() => import("./pages/MapPage.tsx"));
const SchedulePage = lazyWithRetry(() => import("./pages/Schedule.tsx"));
const CreateFormSubmissionPage = lazyWithRetry(
  () => import("./pages/CreateFormSubmissionPage.tsx")
);

export const AppRouter: FC = withAuthenticationRequired(
  () => {
    const {
      hasTimecardAdmin,
      hasRibbiotAdmin,
      hasWebAssetMap,
      hasAssetCrud,
      hasUserCrud,
      hasWebSchedule,
      hasWebQuoting,
      hasWebSettings,
    } = useAuthContext();

    return (
      <>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          {(hasWebAssetMap || hasRibbiotAdmin) && (
            <>
              <Route path="/map" element={<MapPage />} />
              <Route path="/assets/browser" element={<AssetsBrowserPage />} />
              <Route path="/assets/search" element={<AssetsSearchPage />} />
              <Route path="/assets/overview" element={<AssetOverviewPage />} />
              <Route
                path="/assets/asset-images"
                element={<AssetImagesPage />}
              />
              <Route
                path="/assets/asset-documents"
                element={<AssetDocuments />}
              />
            </>
          )}
          {(hasWebSchedule || hasRibbiotAdmin) && (
            <>
              <Route path="/jobs/create" element={<JobPage />} />
              <Route path="/jobs/dashboard" element={<DashboardPage />} />
              <Route path="/jobs/job-detail" element={<JobDetailPage />} />
              <Route path="/jobs/job-documents" element={<JobDocuments />} />
              <Route path="/schedule" element={<SchedulePage />} />
            </>
          )}
          {(hasAssetCrud || hasRibbiotAdmin) && (
            <>
              <Route path="/assets/browser" element={<AssetsBrowserPage />} />
              <Route path="/assets/search" element={<AssetsSearchPage />} />
              <Route path="/assets/overview" element={<AssetOverviewPage />} />
              <Route
                path="/assets/asset-detail"
                element={<AssetDetailPage />}
              />
              <Route
                path="/assets/asset-images"
                element={<AssetImagesPage />}
              />
              <Route
                path="/assets/asset-documents"
                element={<AssetDocuments />}
              />
              <Route path="/assets/create" element={<CreateAssetPage />} />
            </>
          )}

          {(hasUserCrud || hasRibbiotAdmin) && (
            <>
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/user-detail" element={<UserDetailsPage />} />
              <Route path="/users/create" element={<CreateUserPage />} />
            </>
          )}

          {(hasRibbiotAdmin || hasTimecardAdmin) && (
            <>
              <Route path="/documents" element={<DocumentsPage />} />
              <Route
                path="/documents/create"
                element={<CreateFormSubmissionPage />}
              />
              <Route
                path="/documents/document-detail"
                element={<DocumentDetailsPage />}
              />
            </>
          )}
          {(hasRibbiotAdmin || hasWebQuoting) && (
            <>
              <Route path="/quoting" element={<QuotingPage />} />
              <Route
                path="/quoting/quote-detail"
                element={<QuoteDetailsPage />}
              />
              <Route path="/quoting/create" element={<CreateQuotePage />} />
            </>
          )}
          {(hasRibbiotAdmin || hasWebSettings) && (
            <>
              <Route path="/settings" element={<SettingsPage />} />
              <Route
                path="/settings/form-manager"
                element={<SettingsFormManagerPage />}
              />
              <Route
                path="/settings/pricebook"
                element={<SettingsPricebookPage />}
              />
            </>
          )}
          {hasRibbiotAdmin && (
            <>
              <Route path="/admin" element={<AdminAccountPage />} />
              <Route path="/admin/forms" element={<AdminAccountFormsPage />} />
              <Route
                path="/admin/forms/:formid"
                element={<AdminAccountFormDetail />}
              />
              <Route
                path="/admin/forms/create"
                element={<AdminCreateFormPage />}
              />
              <Route
                path="/admin/forms/:formid/edit-raw-form"
                element={<AdminEditRawFormPage />}
              />
              <Route
                path="/admin/forms/:formid/edit-dynamic-document"
                element={<AdminEditDynamicDocument />}
              />
              <Route
                path="/admin/forms/:formid/edit-web-layout"
                element={<AdminEditWebLayout />}
              />
              <Route
                path="/admin/forms/:formid/edit-ios-layout"
                element={<AdminEditMobileLayout type="IOS" />}
              />
              <Route
                path="/admin/forms/:formid/edit-android-layout"
                element={<AdminEditMobileLayout type="Android" />}
              />
              <Route
                path="/admin/forms/:formid/edit-csv-config"
                element={<AdminEditCSVConfig />}
              />
              <Route
                path="/admin/branch/detail"
                element={<AdminBranchPage />}
              />
              <Route
                path="/admin/division/detail"
                element={<AdminDivisionPage />}
              />
              <Route
                path="/admin/division/create"
                element={<AdminCreateDivisionPage />}
              />
              <Route
                path="/admin/branch/create"
                element={<AdminCreateBranchPage />}
              />
              <Route path="/admin/location" element={<AdminAssetsPage />} />
              <Route
                path="/admin/location/:assetid"
                element={<AdminAssetHistoryPage />}
              />
              <Route path="/admin/icons" element={<IconsPage />} />
              <Route path="/admin/users" element={<UsersPage admin />} />
              <Route
                path="/admin/users/create"
                element={<AdminAddUserPage />}
              />
              <Route
                path="/admin/users/user-detail"
                element={<AdminUserDetailsPage />}
              />
              <Route path="/admin/pricebook" element={<AdminPricebookPage />} />
              <Route
                path="/admin/ai-form-builder"
                element={<AdminAIFormBuilderPage />}
              />
              <Route
                path="/admin/forms/:formid/edit-pdf-config"
                element={<AdminEditPDFConfig />}
              />
            </>
          )}
          <Route
            path="*"
            element={
              hasTimecardAdmin === undefined ||
              hasRibbiotAdmin === undefined ? (
                <LoadingSpinner />
              ) : (
                <Page404 />
              )
            }
          />
        </Routes>
      </>
    );
  },
  {
    onRedirecting: () => <LoadingSpinner />,
  }
);
