import { Control, Controller, Path } from "react-hook-form";
import { MouseEventHandler, ReactNode } from "react";
import { Option } from "../../types/option";
import { SearchableSelect } from ".";

type ControlledSearchableSelectProps<T extends object> = {
  className?: string;
  clearAction?: () => void;
  containerClassName?: string;
  control: Control<T>;
  controlledSelect?: (option: Option) => void;
  disabled?: boolean;
  label?: string;
  name: Path<T>;
  onClick?: MouseEventHandler<HTMLSelectElement> | undefined;
  options: Option[];
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  selectorContainerClasses?: string;
  showClear?: ReactNode | string;
};

function ControlledSearchableSelect<T extends object>({
  className,
  clearAction,
  containerClassName,
  control,
  controlledSelect,
  disabled = false,
  label,
  name,
  onClick,
  options,
  placeholder,
  readonly,
  required = false,
  selectorContainerClasses = "",
  showClear,
}: ControlledSearchableSelectProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <SearchableSelect
            className={className}
            clearAction={clearAction}
            containerClassName={containerClassName}
            controlledSelect={controlledSelect ?? onChange}
            disabled={disabled}
            error={error}
            label={label}
            onClick={onClick}
            options={options}
            placeholder={placeholder}
            readonly={readonly}
            selectorContainerClasses={selectorContainerClasses}
            showClear={showClear}
            value={value}
          />
        );
      }}
      rules={{ required }}
    />
  );
}

export { ControlledSearchableSelect };
