import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { User, UserQuerySchema } from "@/types/users/general";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseEditUserMutationParams {
  props: UserQuerySchema;
  userId: string;
}

interface UseEditUserMutationReturn {
  data: User;
}

export const useEditUser = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<
    UseEditUserMutationReturn,
    Error,
    UseEditUserMutationParams
  >({
    mutationFn: async ({ props, userId }) => {
      const response = await fetchWithAuth({
        url: `${usersBaseUrl}/users/${userId}`,
        method: "PATCH",
        body: JSON.stringify(props),
      });

      if (!response.ok) {
        const error = await response.json();

        if (error.message) {
          throw new Error(error.message);
        } else {
          throw new Error("Failed to edit user.");
        }
      }

      return response.json();
    },
    onSuccess: (_data, params) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER, { userId: params.userId }],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
