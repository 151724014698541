import { FC, Fragment, ReactNode } from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "@/utils/helpers/classNames";

type FlyoutProps = {
  actions?: boolean;
  actionsContent?: ReactNode;
  containerClassName?: string;
  content: ReactNode;
  popoverClassName?: string;
  popoverButton: ReactNode;
};

const Flyout: FC<FlyoutProps> = ({
  actions,
  actionsContent,
  containerClassName = "",
  content,
  popoverClassName = "",
  popoverButton,
}) => {
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex w-full items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 outline-none">
        {popoverButton}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={classNames(
            "absolute right-0 z-10 mt-5 flex w-screen max-w-max px-4",
            popoverClassName
          )}
        >
          <div
            className={classNames(
              "max-w-xl flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5",
              containerClassName
            )}
          >
            <div className="p-4">{content}</div>
            {actions && actionsContent && (
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {actionsContent}
              </div>
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export { Flyout };
