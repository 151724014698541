import { FC } from "react";

type Props = {
  name: string;
  hidden?: string;
};

const MobileCheckbox: FC<Props> = ({ name, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-[60px] items-center border-b bg-white px-5">
      <div className="flex items-center gap-x-1">
        <input
          className="h-[18px] w-[18px] rounded-sm border-2"
          disabled
          type="checkbox"
        ></input>
        <span className="text-sm font-medium">{name}</span>
      </div>
    </div>
  );
};

export { MobileCheckbox };
