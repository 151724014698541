import { create } from "zustand";
import { createSelectors } from "./createSelectors";
import { GqlFormTemplate } from "../graphql/graphql";
import { FORM_TYPES } from "@/types/forms/general";

export enum FormBuilderFieldType {
  HEADING = "Heading",
  DATE = "Date",
  BRANCH = "Branch",
  DIVISION = "Division",
  CHECKBOX = "Checkbox",
  RADIO = "Radio",
  TEXTFIELD = "TextField",
  TEXTAREA = "TextArea",
  NUMBERFIELD = "NumberField",
  TIMEFIELD = "TimeField",
  PHOTO = "Photo",
  QUOTEDETAILS = "QuoteDetails",
  USERFIELD = "UserField",
  SIGNATURE = "Signature",
  PICKER = "Picker",
}
export enum FormBuilderFormType {
  TIMECARD = "timecard",
  SAFETY = "safety",
  QUOTE = "quote",
  OTHER = "other",
}

export type FormBuilderDataModel = {
  id: string;
  name: string;
  type: FormBuilderFieldType;
}[];

export type FormBuilderIOSLayout = {
  alerts: GqlFormTemplate["alerts"];
  fields: GqlFormTemplate["fields"];
};

type FormBuilderStore = {
  formName: string;
  setFormName: (formName: string) => void;
  formDescription: string;
  setFormDescription: (formDescription: string) => void;
  AIFormType: FormBuilderFormType;
  setAIFormType: (formType: FormBuilderFormType) => void;
  formType: FORM_TYPES | undefined;
  setFormType: (formType: FORM_TYPES) => void;
  divisionId: string | undefined;
  setDivisionId: (divisionId: string) => void;
  uploadedFile: File | undefined;
  setUploadedFile: (file: File | undefined) => void;
  dataModel: FormBuilderDataModel | undefined;
  setDataModel: (dataModel: FormBuilderDataModel | undefined) => void;
  generatedFormTemplate: GqlFormTemplate | undefined;
  setGeneratedFormTemplate: (generatedFormTemplate: any) => void;
  IOSLayout: string | undefined;
  setIOSLayout: (IOSLayout: string | undefined) => void;
  webLayout: string | undefined;
  setWebLayout: (webLayout: string | undefined) => void;
};

export const useFormBuilderStore = create<FormBuilderStore>((set) => ({
  formName: "",
  setFormName: (formName) => set({ formName }),
  formDescription: "",
  setFormDescription: (formDescription) => set({ formDescription }),
  AIFormType: FormBuilderFormType.TIMECARD,
  setAIFormType: (aiFormType) => set({ AIFormType: aiFormType }),
  formType: undefined,
  setFormType: (formType) => set({ formType }),
  divisionId: undefined,
  setDivisionId: (divisionId) => set({ divisionId }),
  uploadedFile: undefined,
  setUploadedFile: (uploadedFile) => set({ uploadedFile }),
  dataModel: undefined,
  setDataModel: (dataModel) => set({ dataModel }),
  generatedFormTemplate: undefined,
  setGeneratedFormTemplate: (generatedFormTemplate) => {
    set({ generatedFormTemplate });
  },
  IOSLayout: undefined,
  setIOSLayout: (IOSLayout) => {
    set({ IOSLayout });
  },
  webLayout: undefined,
  setWebLayout: (webLayout) => {
    set({ webLayout });
  },
}));

export const useFormBuilderStoreSelectors =
  createSelectors(useFormBuilderStore);
