import { create } from "zustand";
import { createSelectors } from "./createSelectors";

type DangerModal = {
  open: boolean;
  itemToHighlight?: string;
};

type SidebarStore = {
  setUnsavedDataWarning: (warn: boolean) => void;
  setUnsavedDataWarningMessage: (message: string) => void;
  setHrefToHighlight: (href: string) => void;
  setHref: (href: string) => void;
  setDangerModal: (state: DangerModal) => void;
  showUnsavedDataWarning: boolean;
  unsavedDataWarningMessage: string;
  href: string;
  dangerModal: DangerModal;
  hrefToHighlight: string;
};

export const useSidebarStore = create<SidebarStore>((set) => ({
  setUnsavedDataWarning: (warn) => set({ showUnsavedDataWarning: warn }),
  setUnsavedDataWarningMessage: (message) =>
    set({ unsavedDataWarningMessage: message }),
  setHref: (href) => set({ href }),
  setDangerModal: (state) => set({ dangerModal: state }),
  setHrefToHighlight: (href) => set({ hrefToHighlight: href }),
  showUnsavedDataWarning: false,
  unsavedDataWarningMessage:
    "You have unsaved changes. If you leave now, your updates will be lost.",
  href: "",
  dangerModal: { open: false },
  hrefToHighlight: "",
}));

export const useSidebarStoreSelectors = createSelectors(useSidebarStore);
