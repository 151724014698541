/* eslint-disable @typescript-eslint/no-explicit-any */

import { classNames } from "@/utils/helpers/classNames";
import { Controller, useFormContext } from "react-hook-form";
import { FC } from "react";
import { RowField } from "@/types/forms/formEngine";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";

export interface RowRadioFieldProps {
  fieldItem: RowField;
  value: string;
}
export const EditRadioField: FC<RowRadioFieldProps> = ({ fieldItem }) => {
  const { control } = useFormContext();
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);
  const options = fieldItem?.attributes?.options?.split(",");
  const title = transformTitleByFieldItem(fieldItem);

  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "py-2 text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}

      <div className="flex max-w-[500px] items-center gap-x-3">
        {options?.map((option, index) => (
          <div key={option + index} className="flex items-center">
            <Controller
              name={`${fieldItem?.id}`}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <input
                    className="h-4 w-4 border-gray-300 text-theme-green-primary focus:ring-0"
                    id={option}
                    name={`${fieldItem?.id}`}
                    onChange={() => onChange(option)}
                    type="radio"
                    checked={
                      (value?.toLowerCase().trim() ===
                        option.toLowerCase().trim()) as any
                    }
                  />
                );
              }}
            />
            <label
              htmlFor={title}
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
