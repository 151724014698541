import { ArrowMoveDown, ArrowMoveUp, CircledXIcon } from "@/assets";
import { Button } from "@/UI/Button";
// import { ControlledInput } from "@/UI/Input";
import { ControlledMultiSelect } from "@/UI/MultiSelect";
import { ControlledSearchableSelect } from "@/UI/SearchableSelect";
import { Divider } from "@/UI/Divider";
import { StickyHeader } from "@/UI/StickyHeader";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useFormTemplateHomePage } from "@/lib/react-query/queryHooks/useFormTemplateHomePage";
import { useFormTemplates } from "@/lib/react-query/queryHooks/useFormTemplates";
import { useCreateFormTemplateQuickLinkGQL } from "@/lib/react-query/mutationHooks/useCreateFormTemplateQuickLink";
import { useDeleteFormTemplateQuickLinkGQL } from "@/lib/react-query/mutationHooks/useDeleteFormTemplateQuickLinkGQL";
import { useUpdateFormTemplateQuickLinkGQL } from "@/lib/react-query/mutationHooks/useUpdateFormTemplateQuickLinkGQL";
import { v4 as uuid } from "uuid";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  GqlFormTemplateQuickLink,
  // GqlTemplateTypeOrder,
} from "@/lib/graphql/graphql";
import {
  MultiSelectOption,
  MultiSelectOptionSchema,
  Option,
  OptionSchema,
} from "@/types/option";
import * as React from "react";
// import { useUpdateFormTypeAccountGQL } from "@/lib/react-query/mutationHooks/useUpdateFormTypeAccountGQL";
import { toast } from "react-toastify";
import { LoadingSpinner } from "@/UI/Loading";
import { queryClient } from "@/lib/react-query/general";
import { QUERY_KEYS } from "@/lib/react-query/constants";

const quickLinkSchema = z.object({
  controlId: z.string(),
  disabled: z.boolean(),
  divisions: MultiSelectOptionSchema,
  form: OptionSchema.refine((option) => !!option.id, {
    message: "Required",
  }),
  id: z.string().optional(),
});

// const typeOrderSchema = z.object({
//   id: z.string().optional(),
//   name: z.string(),
//   slug: z.string(),
// });

const formManagerSchema = z.object({
  quickLinks: z.array(quickLinkSchema).superRefine((data, ctx) => {
    const globalMatches: number[] = [];

    for (let i = 0; i < data.length; i++) {
      const localMatches = data.filter(
        (item) => item.form.id === data[i].form.id
      );

      if (localMatches.length > 1) {
        globalMatches.push(i);
      }
    }

    if (globalMatches.length > 1) {
      globalMatches.forEach((value) => {
        ctx.addIssue({
          path: [`${value}.form`],
          code: "custom",
          message: "Already selected",
        });
      });

      return;
    }

    return true;
  }),
  // typeOrder: z.array(typeOrderSchema),
});

type FormManager = z.infer<typeof formManagerSchema>;
type QuickLink = z.infer<typeof quickLinkSchema>;
type CRUDQuickLink = QuickLink & { position: number };

const optionDefaultValue = { id: "", value: "" };

const formManagerDefaultValues: FormManager = {
  quickLinks: [],
  // typeOrder: [],
};

export const SettingsFormManagerPage: React.FC = () => {
  const [initialQuickLinks, setInitialQuickLinks] = useState<QuickLink[]>([]);
  const [manualLoading, setManualLoading] = useState(false);

  const { accountId } = useAuthContext();

  const [autoAnimateQuickLinksParent] = useAutoAnimate();
  // const [autoAnimateTypeOrderParent] = useAutoAnimate();

  const { data: homePageData, isLoading: homePageLoading } =
    useFormTemplateHomePage();

  const { data: formTemplatesData, isLoading: formTemplatesLoading } =
    useFormTemplates();

  const {
    mutateAsync: createQuickLinkAsync,
    isPending: createQuickLinkPending,
  } = useCreateFormTemplateQuickLinkGQL();

  const {
    mutateAsync: updateQuickLinkAsync,
    isPending: updateQuickLinkPending,
  } = useUpdateFormTemplateQuickLinkGQL();

  const {
    mutateAsync: deleteQuickLinkAsync,
    isPending: deleteQuickLinkPending,
  } = useDeleteFormTemplateQuickLinkGQL();

  // const { mutateAsync: updateFormTypeAccountAsync } =
  //   useUpdateFormTypeAccountGQL();

  const { data: divisions, isLoading: divisionsLoading } = useDivisions();

  const isLoading =
    homePageLoading ||
    formTemplatesLoading ||
    createQuickLinkPending ||
    updateQuickLinkPending ||
    deleteQuickLinkPending ||
    divisionsLoading ||
    manualLoading;

  const accountQuickLinks =
    homePageData?.data.formTemplateHomePage.quickLinks?.sort((a, b) => {
      if (!a || !b) return 0;
      if (a.ordinal === null || a.ordinal === undefined) return 0;
      if (b.ordinal === null || b.ordinal === undefined) return 0;

      return a.ordinal - b.ordinal;
    });

  // const accountTypeOrder =
  //   homePageData?.data?.formTemplateHomePage?.templateTypeOrder;

  const formTemplateOptions =
    formTemplatesData?.data.formTemplates?.map((formTemplate) => {
      return {
        id: formTemplate?.id ?? "",
        value: formTemplate?.name ?? "",
      };
    }) || [];

  const divisionOptions =
    divisions?.data.map((division) => {
      return { value: division.id ?? "", label: division.divisionName ?? "" };
    }) || [];

  const parseDivision = (divisionId: string) => {
    if (!divisions || !divisions.data) return;

    return divisions.data.find((division) => division.id === divisionId);
  };

  const parseInitialQuickLinks = (
    accountQuickLinks: GqlFormTemplateQuickLink[] | null | undefined
  ) => {
    if (!accountQuickLinks?.length) {
      return [];
    }

    const parsedAccountQuickLinks: QuickLink[] = [];

    for (const quickLink of accountQuickLinks) {
      const quickLinkFormTemplate =
        formTemplatesData?.data?.formTemplates?.find(
          (formTemplate) => formTemplate?.id === quickLink.formId
        );

      const divisions: MultiSelectOption = [];

      if (quickLinkFormTemplate && quickLinkFormTemplate.divisionId) {
        divisions.push({
          label:
            parseDivision(quickLinkFormTemplate.divisionId)?.divisionName ?? "",
          value: quickLinkFormTemplate.divisionId,
        });
      }

      parsedAccountQuickLinks.push({
        controlId: uuid(),
        disabled: true,
        divisions,
        form: { id: quickLink.formId, value: quickLink.displayName ?? "Other" },
      });
    }

    setInitialQuickLinks(parsedAccountQuickLinks);

    return parsedAccountQuickLinks;
  };

  // const parseInitialTypeOrder = (
  //   accountTypeOrder: GqlTemplateTypeOrder[] | null | undefined
  // ) => {
  //   if (!accountTypeOrder?.length) return [{ name: "" }];

  //   const parsedAccountFormTypes = accountTypeOrder.map((formType) => {
  //     return {
  //       name: formType.formType.displayName,
  //       slug: formType.formType.slug,
  //     };
  //   });

  //   return parsedAccountFormTypes;
  // };

  const { control, formState, handleSubmit, setValue, reset } =
    useForm<FormManager>({
      defaultValues: formManagerDefaultValues,
      resolver: zodResolver(formManagerSchema),
    });

  const {
    fields: quickLinks,
    insert: insertQuicklink,
    remove: removeQuickLink,
    move: moveQuickLink,
  } = useFieldArray({
    control,
    name: "quickLinks",
  });

  // const { fields: typeOrders, move: moveTypeOrder } = useFieldArray({
  //   control,
  //   name: "typeOrder",
  // });

  const shouldEditQuickLink = (
    quickLinkToEdit: QuickLink & { position: number },
    originalQuickLink: QuickLink & { position: number }
  ) => {
    if (quickLinkToEdit.form.id !== originalQuickLink.form.id) return true;
    if (quickLinkToEdit.position !== originalQuickLink.position) return true;
    if (quickLinkToEdit.divisions.length !== originalQuickLink.divisions.length)
      return true;

    for (const originalDivision of originalQuickLink.divisions) {
      const match = quickLinkToEdit.divisions.find(
        (item) => item.value === originalDivision.value
      );

      if (!match) return true;
    }

    return false;
  };

  const onSubmit = async (formData: FormManager) => {
    setManualLoading(true);

    const itemsToDelete: QuickLink[] = [];
    const itemsToCreate: CRUDQuickLink[] = [];
    const itemsToUpdate: CRUDQuickLink[] = [];

    for (const initialQuickLink of initialQuickLinks) {
      const match = !!formData.quickLinks.find(
        (formDataQuickLink) =>
          formDataQuickLink.controlId === initialQuickLink.controlId
      );

      if (!match) {
        itemsToDelete.push(initialQuickLink);
      }
    }

    let quickLinkNewPosition = 0;

    for (const formDataQuickLink of formData.quickLinks) {
      const match = initialQuickLinks.find(
        (quickLink) => quickLink.controlId === formDataQuickLink.controlId
      );

      if (!match) {
        itemsToCreate.push({
          ...formDataQuickLink,
          position: quickLinkNewPosition,
        });
      } else {
        const quickLinkOldPosition = initialQuickLinks.indexOf(match);

        const shouldEdit = shouldEditQuickLink(
          { ...formDataQuickLink, position: quickLinkNewPosition },
          { ...match, position: quickLinkOldPosition }
        );

        if (shouldEdit) {
          itemsToUpdate.push({
            ...formDataQuickLink,
            position: quickLinkNewPosition,
          });
        }
      }

      quickLinkNewPosition++;
    }

    try {
      await Promise.all(
        itemsToCreate.map((item) => {
          const form = formTemplatesData?.data?.formTemplates?.find(
            (formData) => formData?.id === item.form.id
          );

          return createQuickLinkAsync({
            accountId,
            branchIds: [],
            displayName: item.form.value,
            divisionIds: item.divisions.map((division) => division.value),
            formId: item.form.id,
            formTypeSlug: form?.formType?.slug || "other",
            ordinal: item.position,
          });
        })
      );

      await Promise.all(
        itemsToUpdate.map((item) => {
          const form = formTemplatesData?.data?.formTemplates?.find(
            (formData) => formData?.id === item.form.id
          );

          return updateQuickLinkAsync({
            accountId,
            branchIds: [],
            displayName: item.form.value,
            divisionIds: item.divisions.map((division) => division.value),
            formId: item.form.id,
            formTypeSlug: form?.formType?.slug || "other",
            ordinal: item.position,
          });
        })
      );

      await Promise.all(
        itemsToDelete.map((item) => {
          const form = formTemplatesData?.data?.formTemplates?.find(
            (formData) => formData?.id === item.form.id
          );

          return deleteQuickLinkAsync({
            accountId,
            formId: item.form.id,
            formTypeSlug: form?.formType?.slug || "other",
          });
        })
      );

      // await Promise.all(
      //   formData.typeOrder.map((typeOrder, index) => {
      //     return updateFormTypeAccountAsync({
      //       accountId,
      //       slug: typeOrder.slug,
      //       input: {
      //         ordinal: index,
      //       },
      //     });
      //   })
      // );

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FORM_TEMPLATE_HOME_PAGE],
      });

      reset(formData);
      toast.success("Form Updated.");
      setManualLoading(false);
    } catch (error) {
      toast.error((error as Error).message);
      setManualLoading(false);
    }
  };

  const handleQuickLinkFormChange = (index: number, option: Option) => {
    setValue(`quickLinks.${index}.form`, option);

    const quickLinkFormTemplate = formTemplatesData?.data?.formTemplates?.find(
      (formTemplate) => formTemplate?.id === option.id
    );

    if (quickLinkFormTemplate && quickLinkFormTemplate.divisionId) {
      setValue(`quickLinks.${index}.divisions`, [
        {
          label:
            parseDivision(quickLinkFormTemplate.divisionId)?.divisionName ?? "",
          value: quickLinkFormTemplate.divisionId,
        },
      ]);
    }
  };

  useEffect(() => {
    if (!homePageData || !accountQuickLinks) return;

    const initialQuickLinks = parseInitialQuickLinks(accountQuickLinks);
    // const initialTyperOrder = parseInitialTypeOrder(accountTypeOrder);

    reset(
      {
        quickLinks: initialQuickLinks,
        // typeOrder: initialTyperOrder
      },
      { keepDirtyValues: true }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homePageData, accountQuickLinks, formTemplatesData]);

  return (
    <section className="relative flex h-full min-h-max w-[800px] flex-col items-center">
      {isLoading && <LoadingSpinner />}

      <form
        className="h-full w-full"
        onSubmit={handleSubmit(onSubmit, (errors) => console.error(errors))}
      >
        <StickyHeader
          id="form-manager-form-header"
          headerClasses="l-[224px] min-w-[1305px]"
        >
          <div className="ml-[100px] flex w-full px-14">
            <div className="flex w-full justify-center">
              <div className="flex max-w-[800px] basis-3/4 content-center justify-between">
                <h1 className="my-auto text-3xl font-semibold">Form Manager</h1>

                <div className="my-auto space-x-4 ">
                  <Button
                    className="rounded-md text-white"
                    disabled={!formState.isDirty || isLoading}
                    label="Save Changes"
                    variant="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </StickyHeader>

        <header className="flex justify-between rounded-t-lg border-x border-t bg-theme-white-secondary p-6">
          <h1 className="text-4xl font-semibold">Form Manager</h1>

          <Button
            className="rounded-md text-white"
            disabled={!formState.isDirty || isLoading}
            label="Save Changes"
            variant="primary"
          />
        </header>

        <Divider additionalClasses="border-gray-300" />

        <div className="flex min-h-[calc(100%-90px)] flex-col gap-y-6 rounded-b-lg border border-t-0 bg-white p-6">
          <div>
            <h2 className="text-lg font-semibold">Quicklink Order</h2>

            <Divider />

            <div
              className="flex flex-col gap-y-6 py-6"
              ref={autoAnimateQuickLinksParent}
            >
              {quickLinks.length ? (
                quickLinks?.map((quickLink, index) => {
                  return (
                    <div key={quickLink.id} className="relative flex gap-x-4">
                      <div className="absolute -left-[55px] flex flex-col items-center">
                        <button
                          type="button"
                          onClick={() => {
                            if (index === 0) return;
                            moveQuickLink(index, index - 1);
                          }}
                        >
                          <ArrowMoveUp />
                        </button>

                        <span className="text-[15px] font-semibold text-[#98999E]">
                          {index + 1}
                        </span>

                        <button
                          type="button"
                          onClick={() => {
                            if (index + 1 === quickLinks.length) return;
                            moveQuickLink(index, index + 1);
                          }}
                        >
                          <ArrowMoveDown />
                        </button>
                      </div>

                      <ControlledSearchableSelect
                        clearAction={() => {
                          setValue(
                            `quickLinks.${index}.form`,
                            optionDefaultValue
                          );
                        }}
                        containerClassName="w-full"
                        control={control}
                        disabled={quickLink.disabled}
                        label="Form"
                        name={`quickLinks.${index}.form`}
                        options={formTemplateOptions}
                        selectorContainerClasses="top-16"
                        showClear
                        controlledSelect={(option) =>
                          handleQuickLinkFormChange(index, option)
                        }
                      />

                      <ControlledMultiSelect
                        control={control}
                        disabled
                        name={`quickLinks.${index}.divisions`}
                        options={divisionOptions}
                        containerClassName="w-full"
                        label="Divisions"
                      />

                      <div className="flex items-center justify-center gap-x-2">
                        <button
                          className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-palette-companyGreen text-xl font-semibold text-white"
                          type="button"
                          onClick={() => {
                            insertQuicklink(index + 1, {
                              controlId: `new-${uuid()}`,
                              disabled: false,
                              divisions: [],
                              form: optionDefaultValue,
                            });
                          }}
                        >
                          +
                        </button>

                        <button
                          className="shrink-0"
                          type="button"
                          onClick={() => {
                            removeQuickLink(index);
                          }}
                        >
                          <CircledXIcon />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Button
                  label="Add Quick Link"
                  variant="primary"
                  type="button"
                  className="rounded-full text-white"
                  onClick={() => {
                    insertQuicklink(0, {
                      controlId: `new-${uuid()}`,
                      disabled: false,
                      divisions: [],
                      form: optionDefaultValue,
                    });
                  }}
                />
              )}
            </div>
          </div>

          {/* <div>
            <h2 className="text-lg font-semibold">Type Order</h2>

            <Divider />

            <div
              className="flex flex-col gap-y-8 py-6"
              ref={autoAnimateTypeOrderParent}
            >
              {typeOrders?.map((typeOrder, index) => (
                <div
                  key={typeOrder.id}
                  className="relative flex items-center gap-x-4"
                >
                  <div className="absolute -left-[55px] flex flex-col items-center">
                    <button
                      type="button"
                      onClick={() => {
                        if (index === 0) return;
                        moveTypeOrder(index, index - 1);
                      }}
                    >
                      <ArrowMoveUp />
                    </button>

                    <span className="text-[15px] font-semibold text-[#98999E]">
                      {index + 1}
                    </span>

                    <button
                      type="button"
                      onClick={() => {
                        if (index + 1 === typeOrders.length) return;
                        moveTypeOrder(index, index + 1);
                      }}
                    >
                      <ArrowMoveDown />
                    </button>
                  </div>

                  <ControlledInput
                    control={control}
                    name={`typeOrder.${index}.name`}
                    disabled
                    containerClasses="w-full"
                  />
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </form>
    </section>
  );
};
