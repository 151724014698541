import { Button } from "@/UI/Button";
import { useFormBuilderStoreSelectors } from "@/lib/zustand/formBuilderStore";
import {
  ArrowUpTrayIcon,
  CloudArrowUpIcon,
  DocumentIcon,
} from "@heroicons/react/20/solid";
import {
  useState,
  DragEvent,
  useCallback,
  FC,
  useRef,
  ChangeEvent,
} from "react";

const UploadFormHeader = () => (
  <div className="mb-6 ml-8 flex items-center">
    <ArrowUpTrayIcon className="text-primary mr-2 h-6 w-6" />
    <h2 className="text-2xl font-semibold">Upload Your Form</h2>
  </div>
);
export const UploadFormStep: FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const uploadedFile = useFormBuilderStoreSelectors.use.uploadedFile();
  const setUploadedFile = useFormBuilderStoreSelectors.use.setUploadedFile();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      processFile(file);
    }
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      processFile(file);
    }
  };

  const processFile = (file: File) => {
    setUploadedFile(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <UploadFormHeader />
      <div className="flex flex-col items-center">
        <p className="mb-8 max-w-lg text-center text-gray-600 ">
          Upload an existing form in PDF, image, or any other format. Our AI
          will analyze it and extract the data model.
        </p>

        <div
          className={`flex w-full max-w-xl cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed p-12 transition-colors ${
            isDragging
              ? "border-theme-green-primary bg-theme-green-primary/5"
              : "border-gray-300 hover:border-theme-green-primary "
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {!uploadedFile ? (
            <>
              <CloudArrowUpIcon className="mb-4 h-16 w-16 text-gray-400" />
              <p className="mb-2 text-lg font-medium">
                Drag and drop your form here
              </p>
              <p className="mb-6 text-sm text-gray-500 ">or</p>
              <label htmlFor="file-upload">
                <Button
                  onClick={handleButtonClick}
                  label="Browse Files"
                  variant="primary"
                  className="rounded-full"
                />
                <input
                  id="file-upload"
                  type="file"
                  ref={fileInputRef}
                  className="sr-only"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={handleFileChange}
                />
              </label>
              <p className="mt-4 text-xs text-gray-500 ">
                Supported formats: PDF, JPG, PNG
              </p>
            </>
          ) : (
            <div className="flex flex-col items-center">
              <p className="mb-6 text-sm text-gray-500 ">
                Proceed to next step to generate data model
              </p>
              <DocumentIcon className="mb-4 h-16 w-16 text-theme-green-primary" />
              <p className="mb-2 text-lg font-medium">{uploadedFile.name}</p>
              <p className="mb-6 text-sm text-gray-500 ">
                {(uploadedFile.size / 1024 / 1024).toFixed(2)} MB
              </p>
              <Button
                variant="secondary"
                onClick={() => {
                  setUploadedFile(undefined);
                }}
                label="Remove"
                className="rounded-md"
              />
            </div>
          )}
        </div>

        {!uploadedFile && (
          <div className="mt-12 text-center">
            <h3 className="mb-3 text-lg font-medium">Dont have a form?</h3>
            <p className="mb-4 text-gray-600 ">
              Build a data model from scratch in the next step.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
