import { FC } from "react";

type Props = {
  circleFill?: string;
};

const CircledXIcon: FC<Props> = ({ circleFill = "#D65D5A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill={circleFill} />
      <path
        d="M7.5 7.5L12 12M16.5 16.5L12 12M12 12L16.5 7.5M12 12L7.5 16.5"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export { CircledXIcon };
