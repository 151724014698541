import { Input } from "@/UI/Input";
import { Select } from "@/UI/Select";
import { TextArea } from "@/UI/TextArea";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import {
  FormBuilderFormType,
  useFormBuilderStoreSelectors,
} from "@/lib/zustand/formBuilderStore";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { FC } from "react";

const GeneralInfoHeader = () => (
  <div className="mb-6 ml-8 flex items-center">
    <InformationCircleIcon className="text-primary mr-2 h-6 w-6" />
    <h2 className="text-2xl font-semibold">General Info</h2>
  </div>
);
const formTypes = Object.values(FormBuilderFormType);
export const GeneralInfoStep: FC = () => {
  const formName = useFormBuilderStoreSelectors.use.formName();
  const setFormName = useFormBuilderStoreSelectors.use.setFormName();
  const divisionId = useFormBuilderStoreSelectors.use.divisionId();
  const setDivisionId = useFormBuilderStoreSelectors.use.setDivisionId();
  const formDescription = useFormBuilderStoreSelectors.use.formDescription();
  const setFormDescription =
    useFormBuilderStoreSelectors.use.setFormDescription();
  const formType = useFormBuilderStoreSelectors.use.AIFormType();
  const setFormType = useFormBuilderStoreSelectors.use.setAIFormType();
  const { data: divisionsData } = useDivisions();
  const divisionOptions =
    divisionsData?.data?.map((division) => ({
      value: division.divisionName ?? "",
      id: division.id ?? "",
    })) ?? [];
  const formTypeOptions =
    formTypes.map((formType) => ({
      value: formType,
      id: formType,
    })) ?? [];

  const divisionValue = divisionOptions.find(
    (division) => division.id === divisionId
  );
  const formTypeValue = formTypeOptions.find(
    (formTypeOption) => formTypeOption.id === formType
  );
  return (
    <div>
      <GeneralInfoHeader />
      <div className="flex flex-col items-center">
        <div className="max-w-lg">
          <p className="mx-autom my-10  text-center text-sm text-gray-500 ">
            This info will not be sent to the llm
          </p>
          <div className="my-4 flex w-full space-x-4">
            <Input
              name="name"
              label="Form Name"
              value={formName}
              containerClasses="!w-full"
              onChange={(e) => {
                setFormName(e.target.value);
              }}
            />
            <Select
              name="division"
              label="Division"
              value={divisionValue}
              options={divisionOptions}
              placeholder="Select a Division"
              className="!w-full"
              onChange={(e) => {
                if (!e?.id) return;
                setDivisionId(e?.id);
              }}
            />
          </div>
          <div className="my-8 flex w-full flex-col space-x-4">
            <TextArea
              name="description"
              label="Form Description"
              containerClassNames="w-full "
              value={formDescription}
              textareaStyles="min-h-[100px]"
              onChange={(e) => {
                setFormDescription(e.target.value);
              }}
            />
          </div>
          <Select
            label="Form Type"
            value={formTypeValue}
            onChange={(selectedOption) => {
              if (!selectedOption?.id) return;
              setFormType(selectedOption?.id as FormBuilderFormType);
            }}
            options={formTypeOptions}
          />
        </div>
      </div>
    </div>
  );
};
