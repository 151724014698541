import { MobileField } from "@/types/forms/general";
import { classNames } from "@/utils/helpers/classNames";
import { getRadioValues } from "@/utils/helpers/forms/formHelpers";
import * as React from "react";
import { useState } from "react";

interface MobileRadioProps {
  field: MobileField;
}

export const MobileRadio: React.FC<MobileRadioProps> = ({ field }) => {
  const [checkedOption, setCheckedOption] = useState("");
  const options = getRadioValues(field?.databinding?.expression ?? "");
  const layout =
    field?.attributes?.layout === "vertical" ? "vertical" : "horizontal";
  return (
    <div
      className={classNames(
        "flex w-full  border-y-2",
        layout === "horizontal" && "divide-x-2",
        layout === "vertical" && "flex-col divide-y-2"
      )}
    >
      {options.map((option) => (
        <div
          key={option}
          className={classNames(
            "flex h-full w-full space-x-2 py-4",
            layout === "horizontal" && "justify-center",
            layout === "vertical" && "justify-start px-8"
          )}
        >
          {option !== checkedOption ? (
            <button onClick={() => setCheckedOption(option)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <circle
                  id="Ellipse 4135"
                  cx="12.5"
                  cy="12"
                  r="11.5"
                  stroke="#636366"
                />
              </svg>
            </button>
          ) : (
            <button onClick={() => setCheckedOption("")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  id="Subtract"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 10.3276 24.1579 8.73507 23.5398 7.28863L13.4142 17.4142L12 18.8284L10.5858 17.4142L5.08579 11.9142L7.91421 9.08579L12 13.1716L21.3145 3.85711C19.1222 1.4852 15.9845 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24Z"
                  fill="#49C699"
                />
              </svg>
            </button>
          )}
          <span>{option}</span>
        </div>
      ))}
    </div>
  );
};
