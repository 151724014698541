import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseDeleteJobMutationParams {
  jobId: string;
}

export const useDeleteJob = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, Error, UseDeleteJobMutationParams>({
    mutationFn: async ({ jobId }) => {
      const response = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${jobId}`,
        method: "DELETE",
      });

      if (!response.ok) {
        const error = await response.json();

        if (error.message) {
          throw new Error(error.message);
        } else {
          throw new Error("Failed to delete job.");
        }
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.JOBS],
      }),
  });
};
