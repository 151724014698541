import { CameraIcon } from "@/assets";

const MobileMultiPhotoPicker = () => {
  return (
    <div className="flex flex-wrap gap-6 p-2">
      <button
        className={
          "flex h-[152px] w-[152px] flex-col items-center justify-center gap-y-1 rounded-md border bg-[#fafdfe] visited:border-red-400"
        }
        disabled
        type="button"
      >
        <CameraIcon />
        <span>Add Photo</span>
      </button>
    </div>
  );
};

export { MobileMultiPhotoPicker };
