import { FC } from "react";

type Props = {
  name: string;
  hidden?: string;
};

const MobileTable: FC<Props> = ({ name, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div
      className="flex h-[60px] items-center justify-center bg-white"
      style={{
        backgroundImage: "linear-gradient(90deg, white 50%, #dadbdc 50%)",
        backgroundSize: "10px 1px",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left bottom",
      }}
    >
      <span className="text-base font-bold text-[#35B888]">{name}</span>
    </div>
  );
};

export { MobileTable };
