import { GqlFormTemplateQuickLinkInput } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";
import { MUTATION_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const updateFormTemplateQuickLink = graphql(`
  mutation UpdateFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {
    updateFormTemplateQuickLink(input: $input) {
      formId
      formType {
        slug
        displayName
        description
        createdAt
        updatedAt
        iconUrl
        bgColor
        iconType
        ordinal
        isGlobal
      }
      displayName
      ordinal
      createdAt
      updatedAt
      accountId
      divisionIds
      branchIds
    }
  }
`);

export const useUpdateFormTemplateQuickLinkGQL = () => {
  const { graphqlRequest } = useAuthContext();

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_QUICK_LINK],
    mutationFn: async (params: GqlFormTemplateQuickLinkInput) =>
      graphqlRequest(updateFormTemplateQuickLink, { input: params }),
  });
};
