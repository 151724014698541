import { Flyout } from "@/UI/Flyout";
import { FilterIcon, MagnifyingGlassIcon } from "@/assets";
import * as React from "react";
import { useState } from "react";
import { Option } from "@/types/option";
import { Input } from "@/UI/Input";
import { SearchableSelect } from "@/UI/SearchableSelect";

interface PricebookFiltersProps {
  branchOptions:
    | {
        id: string;
        value: string;
      }[]
    | undefined;
  divisionOptions:
    | {
        id: string;
        value: string;
      }[]
    | undefined;
  filters: {
    id: string;
    value: string;
  }[];
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        value: string;
      }[]
    >
  >;
}

export const PricebookFilters: React.FC<PricebookFiltersProps> = ({
  branchOptions,
  divisionOptions,
  filters,
  setFilters,
}) => {
  const [branchFilter, setBranchFilter] = useState<Option>();
  const [divisionFilter, setDivisionFilter] = useState<Option>();
  const filtersCount = filters.filter((filter) => filter.value);
  const handleFiltering = (filterValue: Option, originalOption?: Option) => {
    const clonedFilters = structuredClone(filters);
    const index = clonedFilters.findIndex((item) => item.id === filterValue.id);

    if (index >= 0) {
      clonedFilters[index] = filterValue;
    } else {
      clonedFilters.push(filterValue);
    }

    setFilters(clonedFilters);

    switch (filterValue.id) {
      case "branch":
        setBranchFilter(originalOption);
        break;
      case "division":
        setDivisionFilter(originalOption);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Input
        inputClasses="!w-[283px] !pl-6"
        onChange={(ev) =>
          handleFiltering({ id: "name", value: ev.target.value })
        }
        placeholder="Keyword Search (Name)"
        startAdornment={<MagnifyingGlassIcon />}
      />
      <Flyout
        containerClassName="!bg-transparent !shadow-none !ring-0 !w-0"
        popoverClassName="!mt-0"
        popoverButton={
          <div
            className={`
                flex h-10 w-full items-center justify-center gap-x-2 rounded-[50px] 
                bg-theme-green-primary px-10 text-[14px] font-medium text-white 
                hover:opacity-80 active:opacity-80 disabled:bg-theme-black-quinary 
                disabled:text-theme-gray-octonary`}
          >
            {<FilterIcon />}
            {`Filters ${filtersCount.length ? `(${filtersCount.length})` : ""}`}
          </div>
        }
        content={
          <div className="min absolute right-0 flex w-max justify-between gap-x-4 rounded-md border bg-white p-4">
            <SearchableSelect
              containerClassName="w-full"
              inputClasses="bg-white"
              label="Branch"
              options={[
                { id: "", value: "All Branches" },
                ...(branchOptions ? [...branchOptions] : []),
              ]}
              placeholder="Select Branch"
              readonly
              selectorContainerClasses="!top-[65px]"
              value={branchFilter}
              controlledSelect={(option) => {
                if (option) {
                  handleFiltering({ id: "branch", value: option.id }, option);
                }
              }}
            />

            <SearchableSelect
              containerClassName="w-full"
              controlledSelect={(option) => {
                if (option) {
                  handleFiltering({ id: "division", value: option.id }, option);
                }
              }}
              inputClasses="bg-white"
              label="Division"
              options={[
                { id: "", value: "All Divisions" },
                ...(divisionOptions ? [...divisionOptions] : []),
              ]}
              placeholder="Select Division"
              readonly
              selectorContainerClasses="!top-[65px]"
              value={divisionFilter}
            />
          </div>
        }
      />
    </>
  );
};
