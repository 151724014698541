/* eslint-disable @typescript-eslint/no-explicit-any */
export type TimecardInfoTableData = { name: string };

export enum TimecardInfoTableDataStartNames {
  "travelStart",
  "boomAssemblyStart",
  "workStart",
  "boomDisassemblyStart",
  "permitStart",
}

export enum TimecardInfoTableDataStopNames {
  "travelStop",
  "boomAssemblyStop",
  "workStop",
  "boomDisassemblyStop",
  "permitStop",
}

export type DynamicPageField = {
  name: string;
  type: string;
  id?: string;
  source: "metadata" | "fields";
  path?: string;
  map?: { id: string; name: string }[];
};

export type DynamicPage = {
  name: string;
  formId: string;
  divisionId: string;
  bulkDownloads: ("pdf" | "csv")[];
  columns: DynamicPageField[];
  filters: DynamicPageField[];
};

export type MobileField = {
  attributes?: {
    border?: string;
    checkbox?: string;
    hidden?: string;
    required?: string;
    tagText?: string;
    type?: "primary" | "secondary";
    alignment?: "left" | "middle" | "right";
    layout?: "horizontal" | "vertical";
  };
  databinding?: {
    expressionVersion?: string;
    expression?: string;
  };
  fields?: MobileField[];
  name: string;
  type: IOS_FIELD_TYPES;
  id: string;
};

export enum IOS_FIELD_TYPES {
  "Banner" = "Banner",
  "Button" = "Button",
  "Checkbox" = "Checkbox",
  "MinutePicker" = "MinutePicker",
  "MultiPhotoPicker" = "MultiPhotoPicker",
  "NotesBanner" = "NotesBanner",
  "Picker" = "Picker",
  "Radio" = "Radio",
  "Row" = "Row",
  "Text" = "Text",
  "Signature" = "Signature",
  "Space" = "Space",
  "Table" = "Table",
  "TextEditor" = "TextEditor",
  "TextField" = "TextField",
  "NumberField" = "NumberField",
  "TimePicker" = "TimePicker",
}

export enum WEB_FIELD_TYPES {
  "Row" = "Row",
  "Divider" = "Divider",
  "TimeField" = "TimeField",
  "DateField" = "DateField",
  "AdderField" = "AdderField",
  "Checkbox" = "Checkbox",
  "BranchField" = "BranchField",
  "DivisionField" = "DivisionField",
  "UserField" = "UserField",
  "DateTimeField" = "DateTimeField",
  "WeekDateField" = "WeekDateField",
  "MultiUserField" = "MultiUserField",
  "NumberField" = "NumberField",
  "TextArea" = "TextArea",
  "PickerField" = "PickerField",
  "TextField" = "TextField",
  "RadioField" = "RadioField",
  "SignatureField" = "SignatureField",
  "TotalField" = "TotalField",
  "MultiImageField" = "MultiImageField",
  "MultiQuoteItemField" = "MultiQuoteItemField",
  "LocationField" = "LocationField",
}

export enum FORM_TYPES {
  "Timecard" = "Timecard",
  "inspection-form" = "inspection-form",
  "safety-form" = "safety-form",
  "incident-report" = "incident-report",
  "attendance-form" = "attendance-form",
  "authorization" = "authorization",
  "quote" = "quote",
  "invoice" = "invoice",
  "other" = "other",
}
export type FormField = {
  name: string;
  type: string;
  id: string;
  action?: string;
  fields?: Omit<FormField, "fields">[];
  text?: string;
};

export type SubForm = {
  id: string;
  name: string;
  fields: FormField[];
};

export type Form = {
  name: string;
  formVersion: string;
  id: string;
  fields: FormField[];
  subforms?: SubForm[];
  layouts?: Layout;
  alerts?: any;
  divisionId?: string;
  data?: Record<string, string>;
};

export type Layout = {
  web: WebLayout;
  android: AndroidLayout;
};
export type WebLayout = {
  downloadName?: string;
  fields: LayoutField[];
};
export type AndroidLayout = {
  fields: MobileField[];
};
export type LayoutField = {
  type: string;
  name: string | undefined;
  path: string | undefined;
  source: string | undefined;
  columns: number | undefined;
  columnStart: number | undefined;
  fields: LayoutField[];
};

export type FormSubmissionFieldMultiValue = {
  userId: string;
  occupation: string;
};
export type FormSubmissionField = {
  id: string;
  value: string | Record<string, string>[];
  type: string;
  name?: string;
};
export type FormSubmissionData = {
  number?: string;
  status?: string;
  adminNotes?: string;
};
export type FormSubmission = {
  accountId: string;
  id: string;
  submitDateTime: string;
  formId: string;
  localDateTime: string;
  timezoneId: string;
  timezoneOffset: string;
  fields: FormSubmissionField[];
  data: FormSubmissionData;
  status: string | undefined;
  number: string | undefined;
  version: string | undefined;
  userId: string | undefined;
  userName: string | undefined;
  hasFile: boolean | undefined;
  createdAt: string | undefined | null;
  updatedAt: string | undefined | null;
};

export type FormSubmissionHistoryAuditRecord = {
  accountId: string;
  createdAt: string;
  changeNotes: string;
  formId: string;
  formSubmissionId: string;
  id: string;
  userId: string;
  newRecord: FormSubmission;
  oldRecord: FormSubmission;
  diffRecord: { fields: FormSubmissionField[]; localDateTime?: string };
};
