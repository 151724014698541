import { Button } from "@/UI/Button";
import { useState } from "react";
import { WizardTimeline } from "../../../components/AIFormBuilder/WizardTimeline";
import { UploadFormStep } from "../../../components/AIFormBuilder/UploadFormStep";
import { DataModelStep } from "../../../components/AIFormBuilder/DataModel/DataModelStep";
import { IOSLayoutStep } from "../../../components/AIFormBuilder/IOSLayoutStep";
import { WebLayoutStep } from "../../../components/AIFormBuilder/WebLayoutStep";
import { GeneralInfoStep } from "@/components/AIFormBuilder/GeneralInfoStep";
import { SubmitStep } from "@/components/AIFormBuilder/SubmitStep";
const steps = [
  "General Info",
  "Upload Form",
  "Data Model",
  "IOS Layout",
  "Web Layout",
  "Submit",
];

export const AdminAIFormBuilderPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const goToStep = (step: number) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step);
    }
  };

  // Render the current step
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <GeneralInfoStep />;
      case 1:
        return <UploadFormStep />;
      case 2:
        return <DataModelStep />;
      case 3:
        return <IOSLayoutStep />;
      case 4:
        return <WebLayoutStep />;
      case 5:
        return <SubmitStep />;
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <div className="container px-4 py-10">
      <h1 className="mb-8 text-center text-4xl font-bold">AI Form Builder</h1>
      <WizardTimeline
        steps={steps}
        currentStep={currentStep}
        onStepClick={goToStep}
      />

      <div className="mb-10 mt-8 min-h-[600px]">{renderStep()}</div>

      <div className="flex justify-between">
        <Button
          label="Previous"
          className="rounded-full"
          variant="primary"
          onClick={prevStep}
          disabled={currentStep === 0}
        />

        <Button
          onClick={nextStep}
          disabled={currentStep === steps.length - 1}
          label={currentStep === steps.length - 2 ? "Submit" : "Next"}
          variant="primary"
          className="rounded-full"
        />
      </div>
    </div>
  );
};
