import { Button } from "@/UI/Button";
import { GripIcon, EditIcon, CheckIcon, TrashIcon } from "@/assets";
import {
  FormBuilderDataModel,
  FormBuilderFieldType,
  useFormBuilderStoreSelectors,
} from "@/lib/zustand/formBuilderStore";
import { PlusIcon } from "@heroicons/react/20/solid";
import { DragEvent, FC } from "react";
import {
  DataModelTable,
  DataModelTableHeader,
  DataModelTableRow,
  DataModelTableHead,
  DataModelTableBody,
  DataModelTableCell,
} from "./DataModelTableComponents";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { CancelIcon } from "@/assets/CancelIcon";

const fieldTypes = Object.values(FormBuilderFieldType);
const getNewField = () => ({
  id: uuid(),
  name: "Field Name",
  type: FormBuilderFieldType.TEXTFIELD,
});

export const DataModelDnD: FC = () => {
  const [listRef, enableAnimation] = useAutoAnimate<HTMLDivElement>();
  const dataModel = useFormBuilderStoreSelectors.use.dataModel();
  const setDataModel = useFormBuilderStoreSelectors.use.setDataModel();
  const [editingField, setEditingField] = useState<
    FormBuilderDataModel[0] | null
  >(null);
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);
  const [dragOverItemId, setDragOverItemId] = useState<string | null>(null);

  const handleEditField = (field: FormBuilderDataModel[0]) => {
    setEditingField({ ...field });
  };

  const handleSaveEdit = () => {
    if (!editingField || !dataModel) return;
    setDataModel(
      dataModel.map((f) => (f.id === editingField.id ? editingField : f))
    );
    setEditingField(null);
  };

  const handleCancelEdit = () => {
    setEditingField(null);
  };

  const handleDeleteField = (fieldId: string) => {
    enableAnimation(false);
    if (!dataModel) return;
    setDataModel(dataModel.filter((f) => f.id !== fieldId));
  };

  const handleDragStart = (
    e: DragEvent<HTMLTableRowElement>,
    field: FormBuilderDataModel[0]
  ) => {
    enableAnimation(true);
    setDraggedItemId(field.id);

    // set dragging image
    const dragImage = document.createElement("div");
    dragImage.style.width = "350px";
    dragImage.style.height = "50px";
    dragImage.style.backgroundColor = "rgba(255, 128, 0, 0.7)";
    dragImage.style.color = "white";
    dragImage.style.display = "flex";
    dragImage.style.alignItems = "center";
    dragImage.style.justifyContent = "center";
    dragImage.style.borderRadius = "5px";
    dragImage.style.boxShadow = "0 2px 10px rgba(0,0,0,0.2)";
    dragImage.textContent = field.name;
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 75, 20);
    setTimeout(() => document.body.removeChild(dragImage), 0);
  };

  const handleDragOver = (
    e: DragEvent<HTMLTableRowElement>,
    dragOverItemIdEvent: string
  ) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDragOverItemId(dragOverItemIdEvent);
  };

  const handleDrop = (
    e: DragEvent<HTMLTableRowElement>,
    dragDropIndex: number
  ) => {
    if (!dataModel || !draggedItemId) {
      return;
    }
    e.preventDefault();
    const newFields = [...dataModel];
    const indexOfDrop = newFields.findIndex(
      (field: FormBuilderDataModel[0]) => field.id === draggedItemId
    );
    const draggedItemContent = newFields.splice(indexOfDrop, 1)[0];
    newFields.splice(dragDropIndex, 0, draggedItemContent);
    setDraggedItemId(null);
    setDragOverItemId(null);
    setDataModel(newFields);
  };

  const handleDragEnd = () => {
    setDraggedItemId(null);
    setDragOverItemId(null);
  };
  return (
    <div className="bg-card text-card-foreground mb-8 rounded-lg border p-6 shadow-sm ">
      <DataModelTable>
        <DataModelTableHeader>
          <DataModelTableRow>
            <DataModelTableHead>Field Name</DataModelTableHead>
            <DataModelTableHead>Type</DataModelTableHead>
            <DataModelTableHead className="w-[100px] text-right">
              Actions
            </DataModelTableHead>
          </DataModelTableRow>
        </DataModelTableHeader>
        <DataModelTableBody ref={listRef}>
          {dataModel?.map((field, index) => (
            <DataModelTableRow
              key={field.id}
              draggable={!editingField}
              onDragStart={(e) => handleDragStart(e, field)}
              onDragOver={(e) => handleDragOver(e, field.id)}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={(e) => handleDrop(e, index)}
              onDragEnd={handleDragEnd}
              className={`
                  !relative
                  ${
                    draggedItemId === field.id
                      ? "bg-theme-gray-quarternary  opacity-50"
                      : ""
                  }
                  ${
                    dragOverItemId === field.id
                      ? "bg-theme-green-primary/20  opacity-50"
                      : ""
                  }
                `}
            >
              {editingField && editingField.id === field.id ? (
                <>
                  <DataModelTableCell>
                    <input
                      type="text"
                      value={editingField.name}
                      onChange={(e) =>
                        setEditingField({
                          ...editingField,
                          name: e.target.value,
                        })
                      }
                      className="w-full rounded border p-2"
                    />
                  </DataModelTableCell>
                  <DataModelTableCell>
                    <select
                      value={editingField.type}
                      onChange={(e) =>
                        setEditingField({
                          ...editingField,
                          type: e.target.value as FormBuilderFieldType,
                        })
                      }
                      className="w-full rounded border p-2"
                    >
                      {fieldTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </DataModelTableCell>
                  <DataModelTableCell className="text-right">
                    <button onClick={handleSaveEdit}>
                      <CheckIcon />
                    </button>
                    <button onClick={handleCancelEdit}>
                      <CancelIcon />
                    </button>
                  </DataModelTableCell>
                </>
              ) : (
                <>
                  <DataModelTableCell className="text-sm">
                    <div className="absolute -left-5 cursor-move">
                      <GripIcon />
                    </div>
                    {field.name}
                  </DataModelTableCell>
                  <DataModelTableCell className="font-mono">
                    {field.type}
                  </DataModelTableCell>
                  <DataModelTableCell className="text-right">
                    <button onClick={() => handleEditField(field)}>
                      <EditIcon />
                    </button>
                    <button
                      onClick={() => {
                        handleDeleteField(field.id);
                      }}
                    >
                      <TrashIcon fill="orange" />
                    </button>
                  </DataModelTableCell>
                </>
              )}
            </DataModelTableRow>
          ))}

          <DataModelTableRow>
            <DataModelTableCell>
              <Button
                variant="secondary"
                label="Add"
                labelClasses="text-black"
                className="mt-4 rounded-full text-black"
                onClick={() => {
                  if (!dataModel) return;
                  enableAnimation(true);
                  setDataModel([...dataModel, getNewField()]);
                }}
                icon={<PlusIcon />}
              />
            </DataModelTableCell>
          </DataModelTableRow>
        </DataModelTableBody>
      </DataModelTable>
    </div>
  );
};
