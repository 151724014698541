import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

const aiBaseUrl = import.meta.env.VITE_AI_BASE_URL;

interface UseStreamDataModelParams {
  formData: FormData;
}

export const useStreamDataModel = () => {
  const [error, setError] = useState(false);
  const [responses, setResponses] = useState("");
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const { fetchWithAuth } = useAuthContext();
  const abortControllerRef = useRef<AbortController | null>(null);

  const reset = () => {
    setResponses("");
    setIsStreaming(false);
  };

  const stopStream = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsStreaming(false);
  };

  const { mutate: startStream } = useMutation<
    // eslint-disable-next-line no-undef
    ReadableStreamDefaultReader<Uint8Array>,
    Error,
    UseStreamDataModelParams
  >({
    mutationFn: async (params) => {
      abortControllerRef.current = new AbortController();
      const response = await fetchWithAuth({
        url: `${aiBaseUrl}/generate/data-model`,
        method: "POST",
        body: params.formData,
        signal: abortControllerRef.current.signal,
      });
      if (!response.body || !response.ok) {
        setError(true);
        setIsStreaming(false);
        throw new Error("Something went wrong.");
      }
      const reader = response.body.getReader();
      return reader;
    },
    onSuccess: (reader) => {
      setIsStreaming(true);
      const read = async () => {
        const { done, value } = await reader.read();
        if (done) {
          setIsStreaming(false);
          return;
        }
        const text = new TextDecoder("utf-8").decode(value);
        setResponses((responses) => responses + text);
        read();
      };
      read();
    },
    onError: (e) => {
      if (e.name !== "AbortError") {
        toast.error(e.message);
      }
    },
  });
  return { responses, isStreaming, startStream, stopStream, reset, error };
};
