import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { FormTemplatesQuery } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";

interface UseFormTemplatesQueryParams {
  options?: Partial<DefinedInitialDataOptions<{ data: FormTemplatesQuery }>>;
}

const FormTemplates = graphql(`
  query FormTemplates($accountId: ID!) {
    formTemplates(accountId: $accountId) {
      accountId
      divisionId
      formType {
        slug
        displayName
        description
        createdAt
        updatedAt
        iconUrl
        bgColor
        iconType
        ordinal
        isGlobal
      }
      fields {
        type
        text
        path
        name
        id
        databinding {
          expression
        }
        fields {
          type
          text
          path
          name
          id
          databinding {
            expression
          }
        }
      }
      id
      formVersion
      name
      subForms {
        id
        name
        fields {
          type
          text
          path
          name
          id
          databinding {
            expression
          }
          fields {
            type
            text
            path
            name
            id
            databinding {
              expression
            }
          }
        }
      }
      layouts {
        web {
          downloadName
          fields {
            type
            columns
            fields {
              type
              text
              source
              path
              name
              id
              columnStart
              attributes {
                hideTitle
                marginBottom
                marginX
                marginY
                nowrap
                titleTextSize
                valueTextSize
                valueTextWeight
                endText
                nullDash
                startId
                endId
                outId
                addButton
                title
              }
            }
          }
        }
      }
    }
  }
`);

export const useFormTemplates = (params?: UseFormTemplatesQueryParams) => {
  const { graphqlRequest, accountId } = useAuthContext();
  return useQuery({
    queryKey: [QUERY_KEYS.FORM_TEMPLATES],
    queryFn: async () => graphqlRequest(FormTemplates, { accountId }),
    ...(params?.options && { ...params.options }),
  });
};
