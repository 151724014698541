import { MobileField } from "@/types/forms/general";
import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";

type Props = {
  field: MobileField;
};

const MobileBanner: FC<Props> = ({ field }) => {
  if (field?.attributes?.hidden === "true") return <></>;

  return (
    <div
      className={classNames(
        "flex h-[46px] items-center justify-start bg-[#E0F5ED] px-5",
        field?.attributes?.alignment === "left" && "justify-start",
        field?.attributes?.alignment === "right" && "justify-end",
        field?.attributes?.alignment === "middle" && "justify-center"
      )}
    >
      <span className="text-base font-semibold text-black">{field?.name}</span>
    </div>
  );
};

export { MobileBanner };
