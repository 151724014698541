export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_976_127718)">
        <mask
          id="mask0_976_127718"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="black" />
        </mask>
        <g mask="url(#mask0_976_127718)">
          <path
            d="M24.668 11.214L22.788 9.33398L13.3346 18.7871L8.54797 14.0004L6.66797 15.8804L11.4546 20.6671L13.3346 22.5471L15.2146 20.6671L24.668 11.214Z"
            fill="#3CD38B"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_976_127718">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
