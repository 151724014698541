import { FC } from "react";

type Props = {
  name: string;
  required?: boolean;
  hidden?: string;
};

// PICKER

const MobilePicker: FC<Props> = ({ name, required, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-[60px] flex-col justify-center border-b bg-white">
      <p className="px-5 text-[11px] font-semibold uppercase text-[#636366]">
        {name}
      </p>

      <div className="flex w-full items-center px-5">
        <input
          type="text"
          disabled
          className="w-full border-none p-0 text-base font-medium text-black placeholder:font-normal placeholder:text-[#c7c7cb]"
          placeholder={required ? "Required" : "Optional"}
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="8"
          viewBox="0 0 13 8"
          fill="none"
        >
          <path
            id="arrow / chevron_down"
            d="M0 1.41421L6.01041 7.42462L12.0208 1.41422L10.6066 7.28599e-07L6.01041 4.59619L1.41421 0L0 1.41421Z"
            fill="#151B22"
            fillOpacity="0.6"
          />
        </svg>
      </div>
    </div>
  );
};

export { MobilePicker };
