import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { PriceBookItemsQuery } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";

interface UsePricebookItemsQueryParams {
  options?: Partial<DefinedInitialDataOptions<{ data: PriceBookItemsQuery }>>;
}

const PriceBookItems = graphql(`
  query PriceBookItems($filters: ListPriceBookItemsInput!) {
    priceBookItems(filters: $filters) {
      id
      accountId
      divisionIds
      branchIds
      createdAt
      updatedAt
      name
      description
      rate
      unit
      defaultValue
      notes
      category
      status
      active
    }
  }
`);

export const usePricebookItems = (params?: UsePricebookItemsQueryParams) => {
  const { graphqlRequest } = useAuthContext();
  return useQuery({
    queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS],
    queryFn: async () => graphqlRequest(PriceBookItems, { filters: {} }),
    ...(params?.options && { ...params.options }),
  });
};
