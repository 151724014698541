/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ControlledGoogleAutocomplete } from "@/components/JobManagement/JobForm/CustomAutocomplete";
import { CustomLocation } from "@/components/JobManagement/JobForm/CustomLocation";
import { FC, useState } from "react";
import { parseLocationValue } from "@/utils/helpers/parseLocationValue";
import { RowField } from "@/types/forms/formEngine";
import { useFormContext } from "react-hook-form";
import {
  evaluateExpression,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";

type EditLocationFieldProps = {
  fieldItem: RowField;
};

export const EditLocationField: FC<EditLocationFieldProps> = ({
  fieldItem,
}) => {
  const [openLocationMap, setOpenLocationMap] = useState(false);

  const { control, getValues, setValue } = useFormContext();
  const title = transformTitleByFieldItem(fieldItem);
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);

  const value = getValues(fieldItem.id!) as string;

  const customChange = (value: any) => {
    if (!fieldItem.id) return;

    if (typeof value === "string") {
      setValue(fieldItem.id, value);
      return;
    }

    if (typeof value?.target?.value === "string") {
      setValue(fieldItem.id, value.target.value);
      return;
    }

    if (value.lat) {
      setValue(fieldItem.id, `${value.lat},${value.lng},${value.name}`);
      return;
    }
  };

  const onClickEndAdorment = () => {
    setOpenLocationMap(true);
  };

  return (
    <>
      <div
        className={`space-y-1 ${hidden ? "hidden" : ""}`}
        style={{
          gridColumnStart: fieldItem?.columnStart,
          gridColumnEnd: fieldItem?.columnEnd,
        }}
      >
        <div className="relative mr-4 text-base">
          <div className="w-full">
            <ControlledGoogleAutocomplete
              control={control}
              customChange={customChange}
              customValue={parseLocationValue(value)}
              label={title}
              name={fieldItem.id || ""}
              onClickEndAdorment={onClickEndAdorment}
            />
          </div>
        </div>
      </div>

      {openLocationMap && (
        <CustomLocation
          castValueToString
          externalLocation={parseLocationValue(value) || ""}
          fieldName={fieldItem.id}
          open={openLocationMap}
          setOpen={setOpenLocationMap}
          setValue={setValue}
        />
      )}
    </>
  );
};
