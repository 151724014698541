/* eslint-disable @typescript-eslint/no-explicit-any */
import "./customLocation.css";

import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { GlobeIcon, GooglePin, MapPinnedIcon } from "@/assets";
import { Location } from "@/types/jobManagementService/general";
import { MAP_OPTIONS, NORTH_AMERICA_BOUNDS } from "@/utils/helpers/map";
import { MapType } from "@/types/map";
import { Modal } from "@/UI/Modal";
import { UseFormSetValue } from "react-hook-form";
import {
  AdvancedMarker,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

type Props = {
  castValueToString?: boolean;
  externalLocation: Location | string | undefined;
  fieldName?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setValue: UseFormSetValue<any>;
};

const CustomLocation: FC<Props> = ({
  castValueToString = false,
  fieldName = "location",
  externalLocation = "",
  open,
  setOpen,
  setValue,
}) => {
  const [mapType, setMapType] = useState<MapType>("satellite");
  const [newLocation, setNewLocation] = useState<Location>();
  const [position, setPosition] = useState<{ lat: number; lng: number }>();

  const geocoding = useMapsLibrary("geocoding");
  const googleMap = useMap();
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  const onTypeChange = (type: MapType) => {
    googleMap?.setMapTypeId(type);
    setMapType(type);
  };

  const onSave = () => {
    if (newLocation) {
      const input = document.getElementById(
        "custom-google-autocomplete-1"
      ) as HTMLInputElement;

      if (input) {
        input.value = newLocation.name;
      }

      setValue(
        fieldName,
        castValueToString
          ? `${newLocation.lat},${newLocation.lng},${newLocation.name}`
          : newLocation,
        { shouldDirty: true }
      );
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!inputRef.current || !places || !googleMap || !geocoding) return;

    const googleAutocomplete = new places.Autocomplete(inputRef.current, {
      bounds: { ...NORTH_AMERICA_BOUNDS },
      strictBounds: true,
      fields: ["formatted_address", "geometry"],
    });

    googleAutocomplete.addListener("place_changed", () => {
      const place = googleAutocomplete.getPlace();

      if (!place.geometry?.location) return;

      setPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });

      googleMap.panTo({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });

      setNewLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.formatted_address || "",
      });
    });

    if (typeof externalLocation !== "string") {
      inputRef.current.value = externalLocation.name;

      setPosition({
        lat: externalLocation.lat,
        lng: externalLocation.lng,
      });
    }

    googleMap.addListener(
      "click",
      async (mapMouseEvent: google.maps.MapMouseEvent) => {
        if (mapMouseEvent.latLng) {
          setPosition({
            lat: mapMouseEvent.latLng.lat(),
            lng: mapMouseEvent.latLng.lng(),
          });

          googleMap.panTo(mapMouseEvent.latLng);

          const geocoder = new geocoding.Geocoder();

          const geocodadeLocation = await geocoder.geocode({
            location: {
              lat: mapMouseEvent.latLng.lat(),
              lng: mapMouseEvent.latLng.lng(),
            },
          });

          setNewLocation({
            lat: geocodadeLocation.results[0].geometry.location.lat(),
            lng: geocodadeLocation.results[0].geometry.location.lng(),
            name: geocodadeLocation.results[0].formatted_address,
          });

          if (inputRef.current) {
            inputRef.current.value =
              geocodadeLocation.results[0].formatted_address;
          }
        }
      }
    );
  }, [geocoding, googleMap, externalLocation, places]);

  return (
    <Modal open={open} hideButtons>
      <header className="mb-8 flex flex-row items-end justify-between gap-20">
        <div>
          <h1 className="mb-4 text-4xl font-semibold">
            Select Jobsite Location
          </h1>
          <h2 className="text-base font-medium">
            Search for an address or choose a location by clicking on the map
          </h2>
        </div>

        <div className="relative flex grow flex-col">
          <div className="absolute inset-y-0 left-1 flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 stroke-palette-secondaryBlack" />
          </div>

          <input
            className="h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] px-6 text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]"
            id="custom-google-autocomplete-2"
            placeholder="Select a Location"
            ref={inputRef}
          />
        </div>
      </header>

      <div className="relative h-[472px] w-full">
        <div id="rb-job-form-map-wrapper" className="h-full w-full">
          <Map {...MAP_OPTIONS}>
            <AdvancedMarker
              {...(position && {
                position: { lat: position.lat, lng: position.lng },
              })}
            >
              <GooglePin />
            </AdvancedMarker>
          </Map>
        </div>

        {/* map type controls --start */}
        <div className="absolute right-3 top-3 z-10 flex flex-col">
          <button
            className={classNames(
              "flex h-12 w-12 items-center justify-center rounded-t-lg",
              `${
                mapType === "satellite" ? "bg-theme-green-primary" : "bg-white"
              }`
            )}
            onClick={() => onTypeChange("satellite")}
          >
            <GlobeIcon
              opacity={mapType === "satellite" ? false : true}
              className={
                mapType === "satellite"
                  ? "bg-opacity-100 fill-white opacity-100"
                  : "fill-black"
              }
            />
          </button>

          <button
            className={classNames(
              "flex h-12 w-12 items-center justify-center rounded-b-lg",
              `${mapType === "roadmap" ? "bg-theme-green-primary" : "bg-white"}`
            )}
            onClick={() => onTypeChange("roadmap")}
          >
            <MapPinnedIcon
              opacity={mapType === "roadmap" ? false : true}
              className={
                mapType === "roadmap" ? "fill-white !opacity-100" : "fill-black"
              }
            />
          </button>
        </div>
        {/* map type controls --end */}
      </div>

      <footer className="mt-4 flex justify-end gap-x-4">
        <Button
          label="Cancel"
          variant="secondary"
          className="rounded-md"
          onClick={() => {
            setOpen(false);
          }}
        />

        <Button
          className="rounded-md"
          label="Select Location"
          onClick={onSave}
          variant="primary"
        />
      </footer>
    </Modal>
  );
};

export { CustomLocation };
