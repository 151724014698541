import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";

type Props = {
  border?: string;
  hidden?: string;
};

const MobileSpace: FC<Props> = ({ border = "true", hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div
      className={classNames("h-[22px]", border === "true" ? "border-b" : "")}
    ></div>
  );
};

export { MobileSpace };
