import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { BackArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useLocation } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { useExpandedNavigate } from "@/utils/hooks/useExpandedNavigate";

const JobsExpandedContent: FC = () => {
  const { pathname, state } = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("jobId") ?? "";
  const expandedNavigate = useExpandedNavigate();

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
        )}
        onClick={(event) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.JOB, { state }],
          });
          event.preventDefault();
          expandedNavigate("schedule", { skipValidation: true });
        }}
      >
        <BackArrow />

        <span className="text-xs font-medium text-palette-secondaryBlack">
          All Jobs
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/dashboard" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={(event) => {
          if (pathname !== "/jobs/dashboard") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });

            event.preventDefault();
            expandedNavigate(`jobs/dashboard?jobId=${jobId}`, {
              skipValidation: true,
            });
          }
        }}
      >
        <span className="text-sm font-medium">Overview</span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/job-detail" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={(event) => {
          if (pathname !== "/jobs/job-details") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });

            amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_ENTERED);

            event.preventDefault();
            expandedNavigate(`jobs/job-detail?jobId=${jobId}`, {
              skipValidation: true,
            });
          }
        }}
      >
        <span className="text-sm font-medium">Job Details</span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/jobs/job-documents" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={(event) => {
          if (pathname !== "/jobs/job-documents") {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.JOB, { state }],
            });

            amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_ENTERED);

            event.preventDefault();
            expandedNavigate(`jobs/job-documents?jobId=${jobId}`, {
              skipValidation: true,
            });
          }
        }}
      >
        <span className="text-sm font-medium">Job Documents</span>
      </button>
    </>
  );
};

export { JobsExpandedContent };
