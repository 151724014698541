// import { CheckIcon } from "lucide-react";
import { classNames } from "@/utils/helpers/classNames";
import { CheckIcon } from "@heroicons/react/20/solid";
import { FC } from "react";

interface WizardTimelineProps {
  steps: string[];
  currentStep: number;
  onStepClick: (step: number) => void;
}
export const WizardTimeline: FC<WizardTimelineProps> = ({
  steps,
  currentStep,
  onStepClick,
}) => {
  return (
    <div className="container  w-full px-4 py-10">
      <div className="relative">
        <div className="block">
          <ol className="flex items-center">
            {steps.map((step, index) => (
              <li
                key={index}
                className={classNames(
                  "relative flex items-center justify-center",
                  index !== steps.length - 1 ? "flex-1" : ""
                )}
              >
                {index !== steps.length - 1 && (
                  <div
                    className={classNames(
                      "absolute top-1/2 h-[2px] w-full -translate-y-1/2",
                      index < currentStep
                        ? "bg-theme-green-primary"
                        : "bg-gray-200 "
                    )}
                  />
                )}

                <button
                  type="button"
                  className={classNames(
                    "relative z-10 flex h-8 w-8 items-center justify-center rounded-full",
                    "focus:ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2",
                    index < currentStep
                      ? "bg-theme-green-primary"
                      : index === currentStep
                      ? "bg-theme-green-primary"
                      : "bg-gray-300 "
                  )}
                  onClick={() => onStepClick(index)}
                >
                  {index < currentStep ? (
                    <CheckIcon className="h-5 w-5 text-black" />
                  ) : (
                    <span
                      className={classNames(
                        "text-sm font-medium",
                        index === currentStep ? "text-black" : "text-white "
                      )}
                    >
                      {index + 1}
                    </span>
                  )}
                </button>

                {/* Step label */}
                <div className="absolute top-10 whitespace-nowrap text-center text-sm font-medium">
                  <span
                    className={classNames(
                      "block",
                      index <= currentStep ? "text-primary" : "text-gray-500 "
                    )}
                  >
                    {step}
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};
