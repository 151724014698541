import { AlertIcon } from "@/assets";
import {
  getDataModel,
  processIOSErrors,
  processWebErrors,
} from "@/utils/helpers/forms/formHelpers";
import * as React from "react";
import {
  FormBuilderDataModel,
  useFormBuilderStoreSelectors,
} from "@/lib/zustand/formBuilderStore";
import { Form, IOS_FIELD_TYPES, WEB_FIELD_TYPES } from "@/types/forms/general";
import { Input } from "@/UI/Input";
import { useEffect, useState } from "react";

interface ErrorsTabProps {
  type: "IOS" | "Web";
}

export const ErrorsTab: React.FC<ErrorsTabProps> = ({ type }) => {
  const IOSLayout = useFormBuilderStoreSelectors.use.IOSLayout();
  const webLayout = useFormBuilderStoreSelectors.use.webLayout();
  const generatedFormTemplate =
    useFormBuilderStoreSelectors.use.generatedFormTemplate();
  const [dataModel, setDataModel] = useState<
    FormBuilderDataModel | undefined
  >();
  const [searchedDataModel, setSearchedDataModel] = useState<
    FormBuilderDataModel | undefined
  >();

  useEffect(() => {
    if (!IOSLayout) return;
    try {
      const IOSLayoutObject = JSON.parse(IOSLayout);
      const changedDataModel = getDataModel({
        ...(generatedFormTemplate as Form),
        ...IOSLayoutObject,
      });
      setDataModel(changedDataModel);
      setSearchedDataModel(changedDataModel);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getIOSLayoutErrors = () => {
    if (!IOSLayout) return {};
    try {
      const IOSLayoutObject = JSON.parse(IOSLayout);
      return processIOSErrors(IOSLayoutObject);
    } catch (error) {
      return {};
    }
  };
  const getWebLayoutErrors = () => {
    if (!webLayout || !dataModel) return {};
    try {
      const webLayoutObject = JSON.parse(webLayout);
      return processWebErrors(webLayoutObject, dataModel);
    } catch (error) {
      return {};
    }
  };
  const layoutErrors =
    type === "IOS" ? getIOSLayoutErrors() : getWebLayoutErrors();

  const noError =
    (!layoutErrors.duplicateIds || layoutErrors.duplicateIds.length === 0) &&
    (!layoutErrors.invalidTypes || layoutErrors.invalidTypes.length === 0) &&
    (!layoutErrors.invalidIdMapping ||
      layoutErrors.invalidIdMapping.length === 0);

  const fieldTypes =
    type === "IOS"
      ? Object.values(IOS_FIELD_TYPES)
      : Object.values(WEB_FIELD_TYPES);
  return (
    <div className="mx-auto w-full max-w-3xl overflow-hidden rounded-lg border shadow-sm">
      {noError && (
        <header className="bg-muted/50 p-6">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-theme-green-primary">
                No errors found
              </h2>
            </div>
          </div>
        </header>
      )}

      {type === "Web" && !noError && (
        <div className="mr-6 mt-8">
          <div className="flex justify-between p-4">
            <h5 className="text-lg">Data Model</h5>
            <Input
              placeholder="Search"
              onChange={(e) => {
                if (!dataModel) return;
                const filteredDataModel = dataModel.filter((item: any) => {
                  return item.name
                    ?.toLocaleLowerCase()
                    .includes(e.target.value);
                });
                setSearchedDataModel(filteredDataModel);
              }}
            />
          </div>
          <textarea
            disabled
            className="h-[200px] w-full"
            value={JSON.stringify(searchedDataModel, null, 2)}
          />
        </div>
      )}
      {layoutErrors.duplicateIds && layoutErrors?.duplicateIds.length > 0 && (
        <div>
          <header className="bg-muted/50 p-6">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-xl font-semibold">Duplicate ID Errors</h2>
                <p className="text-muted-foreground text-sm">
                  Found {layoutErrors.duplicateIds.length} duplicate IDs that
                  need to be fixed
                </p>
              </div>
            </div>
          </header>
          <div className="p-6">
            <div className="space-y-4">
              {layoutErrors.duplicateIds.map((error, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between rounded border border-red-200 bg-red-50 px-4 py-3 text-red-800"
                  >
                    <div className="flex items-start gap-2">
                      <AlertIcon />
                      <div>
                        <h3 className="text-sm font-medium">{error}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {layoutErrors?.invalidTypes && layoutErrors?.invalidTypes.length > 0 && (
        <div>
          <header className="bg-muted/50 p-6">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-xl font-semibold">Invalid Types</h2>
                <p className="text-muted-foreground text-sm">
                  Found {layoutErrors.invalidTypes.length} invalid field types
                  that need to be fixed
                </p>
                <p className="text-muted-foreground my-2 text-sm">
                  Correct types are
                </p>
                <ul className="grid w-full grid-cols-3 gap-x-5">
                  {fieldTypes.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              </div>
            </div>
          </header>
          <div className="p-6">
            <div className="space-y-4">
              {layoutErrors.invalidTypes.map((error, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between rounded border border-red-200 bg-red-50 px-4 py-3 text-red-800"
                  >
                    <div className="flex items-start gap-2">
                      <AlertIcon />
                      <div>
                        <h3 className="text-sm font-medium">ID: {error.id}</h3>
                        <h3 className="text-sm font-medium">
                          Name: {error.name}
                        </h3>
                        <h3 className="text-sm font-medium">
                          Type: {error.type}
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {layoutErrors?.invalidIdMapping &&
        layoutErrors?.invalidIdMapping.length > 0 && (
          <div>
            <header className="bg-muted/50 p-6">
              <div className="flex w-full flex-col  justify-between">
                <div>
                  <h2 className="text-xl font-semibold">Invalid ID Mappings</h2>
                  <p className="text-muted-foreground text-sm">
                    Found {layoutErrors.invalidIdMapping.length} invalid field
                    id mappings that need to be fixed
                  </p>
                </div>
              </div>
            </header>
            <div className="p-6">
              <div className="space-y-4">
                {layoutErrors.invalidIdMapping.map((error, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between rounded border border-red-200 bg-red-50 px-4 py-3 text-red-800"
                    >
                      <div className="flex items-start gap-2">
                        <AlertIcon />
                        <div>
                          <h3 className="text-sm font-medium">
                            ID: {error.id}
                          </h3>
                          <h3 className="text-sm font-medium">
                            Name: {error.name}
                          </h3>
                          <h3 className="text-sm font-medium">
                            Type: {error.type}
                          </h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
