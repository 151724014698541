import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";
import { UpdatePriceBookItemInput } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";
import { MUTATION_KEYS } from "../constants";

const updatePricebookBookItem = graphql(`
  mutation UpdatePriceBookItem($input: UpdatePriceBookItemInput!) {
    updatePriceBookItem(input: $input) {
      code
      success
      message
      priceBookItem {
        id
        accountId
        divisionIds
        branchIds
        createdAt
        updatedAt
        name
        description
        rate
        unit
        defaultValue
        notes
        category
        status
        active
      }
    }
  }
`);

export const useGQLUpdatePricebookItem = () => {
  const { graphqlRequest } = useAuthContext();
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_PRICEBOOK_ITEM],
    mutationFn: async (input: UpdatePriceBookItemInput) =>
      graphqlRequest(updatePricebookBookItem, { input }),
  });
};
