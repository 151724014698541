import { Button } from "@/UI/Button";
import {
  FormBuilderDataModel,
  FormBuilderFieldType,
  useFormBuilderStoreSelectors,
} from "@/lib/zustand/formBuilderStore";
import {
  ArrowPathIcon,
  ChevronDoubleLeftIcon,
  DocumentIcon,
  TableCellsIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { useStreamDataModel } from "@/lib/react-query/mutationHooks/useStreamDataModel";
import { SmallLoadingSpinner } from "@/UI/Loading/SmallLoadingSpinner";
import { DataModelDnD } from "./DataModelDnD";

const DataModelHeader = () => (
  <div className="mb-6 ml-8 flex items-center">
    <TableCellsIcon className="text-primary mr-2 h-6 w-6" />
    <h2 className="text-2xl font-semibold">Data Model</h2>
  </div>
);
const getNewField = () => ({
  id: uuid(),
  name: "Field Name",
  type: FormBuilderFieldType.TEXTFIELD,
});

export const DataModelStep = () => {
  const [generating, setGenerating] = useState<boolean>(false);
  const formType = useFormBuilderStoreSelectors.use.AIFormType();
  const dataModel = useFormBuilderStoreSelectors.use.dataModel();
  const setDataModel = useFormBuilderStoreSelectors.use.setDataModel();
  const { startStream, responses, isStreaming, reset, stopStream } =
    useStreamDataModel();
  const uploadedFile = useFormBuilderStoreSelectors.use.uploadedFile();
  const handleGenerateDataModel = async () => {
    if (!uploadedFile || !formType) return;
    setDataModel(undefined);
    reset();
    const formData = new FormData();
    formData.append("file", uploadedFile);
    formData.append("formType", formType ?? "timecard");
    setGenerating(true);
    startStream({ formData });
  };

  useEffect(() => {
    if (isStreaming || !responses) return;
    try {
      const jsonData = JSON.parse(responses).data_model as FormBuilderDataModel;
      const formattedDataModel = jsonData.map((item) => ({
        id: uuid(),
        name: item.name,
        type: item.type,
      }));
      setGenerating(false);
      setDataModel(formattedDataModel);
      toast.success("Data Model Generated");
    } catch (error) {
      toast.error("Unable to parse JSON, please try again");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStreaming]);

  if (generating) {
    return (
      <div>
        <DataModelHeader />
        <div className=" w-full">
          <p className="mb-8 text-center text-gray-600 ">
            This may take a minute...
          </p>
          <div className="bg-card text-card-foreground w-full rounded-lg border shadow-sm">
            <div className="p-4  pt-0">
              <div className="bg-muted/50 h-[400px] overflow-y-auto rounded-md p-4 font-mono">
                {responses ? (
                  <div className="whitespace-pre-wrap break-words">
                    {responses}
                  </div>
                ) : (
                  <div className="text-muted-foreground flex h-full items-center justify-center">
                    {isStreaming ? (
                      <div className="flex items-center gap-2">
                        <SmallLoadingSpinner />
                        <span>Receiving stream...</span>
                      </div>
                    ) : (
                      <span>Stream content will appear here</span>
                    )}
                  </div>
                )}
              </div>
              {isStreaming && (
                <div className="text-muted-foreground mt-2 flex items-center gap-2 text-sm">
                  <div className="relative h-2 w-2">
                    <div className="absolute h-2 w-2 animate-ping rounded-full bg-green-500"></div>
                    <div className="absolute h-2 w-2 rounded-full bg-green-500"></div>
                  </div>
                  <span>Live streaming</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <Button
            onClick={() => {
              stopStream();
              setDataModel(undefined);
              setGenerating(false);
            }}
            variant="primary"
            label="Re-analyze Form"
            disabled={!formType}
            className="my-4 rounded-full"
            icon={<ArrowPathIcon className="h-6 w-6" />}
          />
        </div>
      </div>
    );
  }
  if (!dataModel) {
    return (
      <div>
        <DataModelHeader />
        {uploadedFile ? (
          <div className="flex flex-col items-center">
            <DocumentIcon className="mb-4 h-16 w-16 text-theme-green-primary" />
            <p className="mb-2 text-lg font-medium">{uploadedFile.name}</p>
            <p className="mb-6 text-sm text-gray-500 ">
              {(uploadedFile.size / 1024 / 1024).toFixed(2)} MB
            </p>
            <p className="mb-6 text-sm text-gray-500 ">
              <strong>Form Type: </strong>
              {formType}
            </p>

            <Button
              onClick={handleGenerateDataModel}
              variant="primary"
              disabled={!formType}
              label="Generate Data Model"
              className="mr-2 rounded-full"
              icon={<ArrowPathIcon className="h-6 w-6" />}
            />
            <p className="mt-8 text-sm text-gray-500 ">
              Remove file in previous step to start from scratch
            </p>
          </div>
        ) : (
          <div className="my-8 flex flex-col items-center space-y-8 text-gray-600">
            <p>Upload a file in the previous step.</p>
            <p>OR</p>
            <Button
              onClick={() => setDataModel([getNewField()])}
              variant="primary"
              label="Create Data Model from Scratch"
              className="mr-2 rounded-full"
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <DataModelHeader />
      <p className="mb-8 text-gray-600 ">
        Our template generator AI will analyze the following data model. You can
        edit, add, or remove fields as needed.
      </p>
      <DataModelDnD />
      <div className="flex  justify-end">
        <div className="flex justify-end">
          <Button
            onClick={() => {
              setDataModel(undefined);
            }}
            variant="primary"
            label="Reset"
            className="mr-2 rounded-full"
            icon={<ChevronDoubleLeftIcon className="h-6 w-6" />}
          />
        </div>
        {dataModel && uploadedFile && (
          <div className="flex justify-end">
            <Button
              onClick={() => {
                setDataModel(undefined);
                handleGenerateDataModel();
              }}
              variant="primary"
              label="Regenerate"
              className="mr-2 rounded-full"
              icon={<ArrowPathIcon className="h-6 w-6" />}
            />
          </div>
        )}
      </div>
    </div>
  );
};
