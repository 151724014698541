import { FC } from "react";

type Props = {
  name: string;
  required?: boolean;
  tagText?: string;
  hidden?: string;
};

// TEXTFIELD

const MobileInput: FC<Props> = ({
  name,
  required,
  hidden = "false",
  tagText,
}) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-[60px] flex-col justify-center border-b bg-white">
      <p className="px-5 text-[11px] font-semibold uppercase text-[#636366]">
        {name}
      </p>

      <div className="flex w-full items-center px-5">
        <input
          type="text"
          disabled
          className="w-full border-none p-0 text-base font-medium text-black placeholder:font-normal placeholder:text-[#c7c7cb]"
          placeholder={required ? "Required" : "Optional"}
        />

        {tagText && (
          <span className="text-[15px] font-medium text-[#8E8E92]">
            {tagText}
          </span>
        )}
      </div>
    </div>
  );
};

export { MobileInput };
