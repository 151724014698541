import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";

type Props = {
  name: string;
  hidden?: string;
};

const MobileButton: FC<Props> = ({ name, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  const type = name.toLowerCase().includes("submit") ? "secondary" : "primary";

  return (
    <div className="flex items-center justify-center">
      <button
        disabled
        className={classNames(
          "flex h-[50px] w-[359px] items-center justify-center rounded-[14px]",
          type === "primary"
            ? "bg-[#34b888]"
            : "border border-[#34b888] bg-white"
        )}
      >
        <span
          className={classNames(
            "text-[17px] font-semibold",
            type === "primary" ? "text-black" : "text-[#34b888]"
          )}
        >
          {name}
        </span>
      </button>
    </div>
  );
};

export { MobileButton };
