import { Button } from "@/UI/Button";
import { useFormBuilderStoreSelectors } from "@/lib/zustand/formBuilderStore";
import { ComputerDesktopIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormRenderer } from "../Forms/FormEngine/FormRenderer";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import { TextArea } from "@/UI/TextArea";
import { classNames } from "@/utils/helpers/classNames";
import { v4 as uuid } from "uuid";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useUser } from "@/lib/react-query/queryHooks/useUser";
import { getFakeFormSubmission } from "@/utils/helpers/forms/formHelpers";
import { FormSubmission } from "@/types/forms/general";
import { LoadingSpinner } from "@/UI/Loading";
import { ErrorsTab } from "./ErrorsTab";
import { toast } from "react-toastify";

const WebLayoutHeader = () => (
  <div className="mb-6 ml-8 flex items-center">
    <ComputerDesktopIcon className="h-6 w-6 text-gray-500" />
    <h2 className="text-2xl font-semibold">Web Layout</h2>
  </div>
);

export const WebLayoutStep = () => {
  const [isInvalidJSON, setIsInvalidJSON] = useState<boolean>(false);
  const [toggleMode, setToggleMode] = useState<"JSON" | "Errors">("JSON");
  const generatedFormTemplate =
    useFormBuilderStoreSelectors.use.generatedFormTemplate();
  const webLayout = useFormBuilderStoreSelectors.use.webLayout();
  const setFormObject = useFormRendererStoreSelectors.use.setFormObject();
  const setFormSubmission =
    useFormRendererStoreSelectors.use.setFormSubmission();
  const setWebLayout = useFormBuilderStoreSelectors.use.setWebLayout();
  const { data: accountData, isLoading: accountLoading } = useAccount({
    options: {
      staleTime: 5 * 1000 * 60,
      gcTime: 5 * 1000 * 60,
    },
  });
  const { data: userData, isLoading: userLoading } = useUser();
  const loading = accountLoading || userLoading;

  const fakeFormSubmission = getFakeFormSubmission({
    form: generatedFormTemplate,
    account: accountData?.data,
    userData: userData?.data,
  });

  useEffect(() => {
    if (loading || webLayout) return;
    setFormObject(generatedFormTemplate);
    setFormSubmission(fakeFormSubmission as unknown as FormSubmission);
    setWebLayout(
      JSON.stringify(generatedFormTemplate?.layouts?.web?.fields, null, 2)
    );
  }, [loading]);

  if (!generatedFormTemplate) {
    return (
      <div>
        <WebLayoutHeader />
        <div className="my-8 flex flex-col items-center space-y-8 text-gray-600">
          <p>Generate a template in the previous step to continue.</p>
        </div>
      </div>
    );
  }
  if (loading) {
    return (
      <div>
        <WebLayoutHeader />
        <div className="my-8 flex flex-col items-center space-y-8 text-gray-600">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  return (
    <div>
      <WebLayoutHeader />

      <div className="my-4 flex w-fit rounded-md border  border-theme-gray-octonary">
        <button
          className={classNames(
            "h-[38px] w-[94px] rounded-md border-l border-palette-tertiaryBlack",
            toggleMode === "JSON" ? "border-r bg-palette-companyGreen" : ""
          )}
          onClick={() => {
            setToggleMode("JSON");
          }}
        >
          <p className="text-sm font-medium">JSON</p>
        </button>
        <button
          className={classNames(
            "h-[38px] w-[94px] rounded-md border-palette-tertiaryBlack",
            toggleMode === "Errors" ? "border-x bg-palette-companyGreen" : ""
          )}
          onClick={() => {
            setToggleMode("Errors");
          }}
        >
          <p className="text-sm font-medium">Errors</p>
        </button>
      </div>
      <div className="flex min-h-[600px] space-x-4">
        {toggleMode === "JSON" ? (
          <div className="relative w-full rounded-md">
            <TextArea
              textareaStyles="!resize-none !h-full"
              containerClassNames={classNames(
                "!h-[calc(100%-24px)]",
                isInvalidJSON && "border-2 border-red-500"
              )}
              value={webLayout}
              onChange={(ev) => {
                setWebLayout(ev.target.value ?? "");
                try {
                  const newFormObject = {
                    ...generatedFormTemplate,
                    layouts: {
                      web: {
                        downloadName: "Download",
                        fields: JSON.parse(ev.target.value ?? ""),
                      },
                    },
                  };
                  setFormObject(newFormObject);
                  setIsInvalidJSON(false);
                } catch (error) {
                  setIsInvalidJSON(true);
                }
              }}
            />
            <button
              className="absolute right-48 top-5 rounded-full border bg-amber-100 px-4 py-1 text-sm"
              onClick={() => {
                const newUuid = uuid();
                navigator.clipboard
                  .writeText(newUuid)
                  .then(() => {
                    toast.success(`New UUID copied to clipboard`);
                  })
                  .catch(() => {
                    toast.error("Something went wrong");
                  });
              }}
            >
              UUID
            </button>
            <button
              className="absolute right-24 top-5 rounded-full border bg-slate-200 px-4 py-1 text-sm"
              onClick={() => {
                if (!webLayout) return;
                try {
                  const parsedWebLayout = JSON.parse(webLayout);
                  setWebLayout(JSON.stringify(parsedWebLayout, null, 2));
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              Beautify
            </button>
            <button
              className="absolute right-5 top-5 rounded-full border bg-red-200 px-4 py-1 text-sm"
              onClick={() => {
                setWebLayout(
                  JSON.stringify(
                    generatedFormTemplate.layouts?.web?.fields,
                    null,
                    2
                  )
                );
                setIsInvalidJSON(false);
              }}
            >
              Reset
            </button>
          </div>
        ) : (
          <ErrorsTab type={"Web"} />
        )}
        <div className="w-full rounded-md ">
          <div className="w-full basis-1/2 overflow-y-auto rounded-md border border-palette-tertiaryBlack bg-white">
            <ErrorBoundary
              fallbackRender={({ resetErrorBoundary }) => (
                <>
                  <div className="my-10 flex flex-col justify-center">
                    <h4 className="mx-auto text-center text-3xl text-red-500">
                      An Error Occured. Fix layout and click reset.
                    </h4>
                    <Button
                      label="Reset"
                      variant="quinary"
                      className="mx-auto mt-4 w-fit"
                      onClick={resetErrorBoundary}
                    />
                  </div>
                </>
              )}
            >
              <FormRenderer />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};
