import { ControlledInput } from "@/UI/Input";
import { LoadingSpinner } from "@/UI/Loading";
import { Modal } from "@/UI/Modal";
import { ControlledMultiSelect } from "@/UI/MultiSelect";
import { ControlledTextArea } from "@/UI/TextArea";
import { Maybe } from "@/lib/graphql/graphql";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useCreatePricebookItem } from "@/lib/react-query/mutationHooks/useCreatePricebookItem";
import { useGQLUpdatePricebookItem } from "@/lib/react-query/mutationHooks/useGQLUpdatePricebookItem";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { MultiSelectOption, MultiSelectOptionSchema } from "@/types/option";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { PricebookModal } from "@/types/pricebook/general";

const pricebookDetailSchema = z.object({
  name: z.string().min(1, { message: "Please provide a Form Name." }),
  description: z
    .string()
    .min(1, { message: "Please provide an item description." }),
  divisionIds: MultiSelectOptionSchema,
  branchIds: MultiSelectOptionSchema,
  rate: z.number().min(1, { message: "Please provide a positive number" }),
  unit: z.string().min(1, { message: "Please provide a unit" }),
  category: z.string().min(1, { message: "Please provide a category" }),
});

type PricebookDetailSchema = z.infer<typeof pricebookDetailSchema>;

const pricebookDetailDefaultValues = {
  name: "",
  description: "",
  rate: 0,
  unit: "",
  category: "",
  divisionIds: [],
  branchIds: [],
};

interface PricebookItemModalProps {
  setModal: Dispatch<SetStateAction<PricebookModal>>;
  modalState: PricebookModal;
}

export const PricebookItemModal: FC<PricebookItemModalProps> = ({
  setModal,
  modalState,
}) => {
  const { data: branches } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const { data: divisions } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const branchOptions = branches?.data?.map((branchOption) => ({
    label: branchOption.name,
    value: branchOption.id,
  }));

  const divisionOptions = divisions?.data?.map((divisionOption) => ({
    label: divisionOption.divisionName,
    value: divisionOption.id,
  }));

  const parseInitialBranches = (ids?: Maybe<string[]>) => {
    if (!branches?.data || !ids) return [];

    const parsedBranches: MultiSelectOption = [];

    for (const id of ids) {
      const match = branches.data.find((branch) => branch.id === id);
      if (match) {
        parsedBranches.push({ value: match.id, label: match.name });
      }
    }

    return parsedBranches;
  };

  const parseInitialDivisions = (ids?: Maybe<string[]>) => {
    if (!divisions?.data || !ids) return [];

    const parsedDivisions: MultiSelectOption = [];

    for (const id of ids) {
      const match = divisions.data.find((division) => division.id === id);
      if (match) {
        parsedDivisions.push({
          label: match.divisionName || "",
          value: match.id || "",
        });
      }
    }

    return parsedDivisions;
  };

  const handleClose = () => {
    setModal((prev) => ({
      open: false,
      title: prev.title,
      pricebook: undefined,
    }));

    reset(pricebookDetailDefaultValues);
  };

  const { control, handleSubmit, setValue, formState, reset } =
    useForm<PricebookDetailSchema>({
      defaultValues: pricebookDetailDefaultValues,
      resolver: zodResolver(pricebookDetailSchema),
    });

  const createPricebookItem = useCreatePricebookItem();
  const updatePricebookItem = useGQLUpdatePricebookItem();

  const onSubmit = async (data: PricebookDetailSchema) => {
    const pricebookItemBody = {
      name: data.name,
      rate: data.rate,
      unit: data.unit,
      category: data.category,
      description: data.description,
      branchIds: data.branchIds.map((branch) => branch.value),
      divisionIds: data.divisionIds.map((division) => division.value),
    };

    if (modalState.pricebook) {
      await updatePricebookItem.mutateAsync(
        {
          ...pricebookItemBody,
          id: modalState.pricebook.id,
        },
        {
          onSuccess: async () => {
            toast.success("Pricebook Item Edited");

            await queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS],
            });

            amplitude.track(AMPLITUDE_EVENTS.PRICEBOOK_ITEM_UPDATED, {
              pricebook_item_name: pricebookItemBody.name,
            });
          },
          onError: (error) => {
            toast.error("Error editing pricebook item");

            amplitude.track(AMPLITUDE_EVENTS.PRICEBOOK_ITEM_FAILED, {
              pricebook_item_name: pricebookItemBody.name,
              error_code: error.message,
            });
          },
        }
      );

      handleClose();
      return;
    }

    await createPricebookItem.mutateAsync(pricebookItemBody, {
      onSuccess: async () => {
        toast.success("Pricebook Item Created");

        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS],
        });

        amplitude.track(AMPLITUDE_EVENTS.PRICEBOOK_ITEM_CREATED, {
          pricebook_item_name: pricebookItemBody.name,
        });
      },
      onError: (error) => {
        toast.error("Error creating pricebook item");

        amplitude.track(AMPLITUDE_EVENTS.PRICEBOOK_ITEM_FAILED, {
          pricebook_item_name: pricebookItemBody.name,
          error_code: error.message,
        });
      },
    });

    handleClose();
  };

  const onError = (error: FieldErrors<PricebookDetailSchema>) =>
    console.error(error);

  useEffect(() => {
    if (!modalState.pricebook) return;

    reset({
      branchIds: parseInitialBranches(modalState.pricebook.branchIds),
      category: modalState.pricebook.category || "",
      description: modalState.pricebook.description || "",
      divisionIds: parseInitialDivisions(modalState.pricebook.divisionIds),
      name: modalState.pricebook.name,
      rate: modalState.pricebook.rate,
      unit: modalState.pricebook.unit,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.pricebook]);

  return (
    <Modal
      childrenContainerClasses="flex flex-col gap-y-4 mb-8"
      confirmButtonText="Confirm"
      disableConfirm={!formState.isDirty}
      innerContainerClasses="!w-max"
      onCancel={handleClose}
      onConfirm={handleSubmit(onSubmit, onError)}
      open={modalState.open}
      title={modalState.title}
      titleClasses="mb-4"
    >
      {createPricebookItem.isPending && <LoadingSpinner />}

      <ControlledInput
        control={control}
        name="name"
        label="Item Name"
        containerClasses="!w-full"
      />

      <ControlledTextArea
        control={control}
        name="description"
        label="Form Description"
        containerClassNames="w-full"
        textareaStyles="h-32"
        error={formState.errors.description}
      />

      <ControlledInput
        control={control}
        name="category"
        label="Item Category"
        containerClasses="!w-full"
      />

      <div className="flex gap-x-4">
        <ControlledInput
          containerClasses="w-64"
          control={control}
          label="Rate"
          name="rate"
          onChange={(e) => setValue("rate", Number(e.target.value))}
          type="number"
        />

        <ControlledInput
          control={control}
          name="unit"
          label="Unit"
          containerClasses="w-64"
        />
      </div>

      <div className="flex gap-x-4">
        <ControlledMultiSelect<PricebookDetailSchema>
          control={control}
          name="divisionIds"
          label="Divisions"
          options={divisionOptions}
          containerClassName="w-64 text-sm"
        />

        <ControlledMultiSelect<PricebookDetailSchema>
          control={control}
          name="branchIds"
          label="Branches"
          options={branchOptions}
          containerClassName="w-64 text-sm"
        />
      </div>
    </Modal>
  );
};
