import { GqlFormTemplateQuickLinkInput } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";
import { MUTATION_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const deleteFormTemplateQuickLink = graphql(`
  mutation DeleteFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {
    deleteFormTemplateQuickLink(input: $input)
  }
`);

export const useDeleteFormTemplateQuickLinkGQL = () => {
  const { graphqlRequest } = useAuthContext();

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_QUICK_LINK],
    mutationFn: async (params: GqlFormTemplateQuickLinkInput) =>
      graphqlRequest(deleteFormTemplateQuickLink, { input: params }),
  });
};
