/* eslint-disable @typescript-eslint/no-explicit-any */

import { lazy, ComponentType } from "react";

type ComponentImportType = () => Promise<{ default: ComponentType<any> }>;

export async function importWithRetry(componentImport: ComponentImportType) {
  const hasRefreshed =
    globalThis.sessionStorage.getItem("lazyWithRetry") || "false";

  try {
    globalThis.sessionStorage.setItem("lazyWithRetry", "false");
    await componentImport();
  } catch (error) {
    if (hasRefreshed === "false") {
      globalThis.sessionStorage.setItem("lazyWithRetry", "true");
      globalThis.location.reload();
    }
    if (hasRefreshed === "true") {
      throw new Error("chunkLoadError");
    }
  }

  return componentImport();
}

export default function lazyWithRetry(componentImport: ComponentImportType) {
  return lazy(() => importWithRetry(componentImport));
}
