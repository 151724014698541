import { Job } from "@/types/jobManagementService/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseEditJobDatesMutationParams {
  props: { startDate: Date | string; endDate: Date | string; id: string };
}

export const useEditJobDates = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<Job, Error, UseEditJobDatesMutationParams>({
    mutationFn: async ({ props }) => {
      const response = await fetchWithAuth({
        url: `${jobsBaseUrl}/update-job-dates/${props.id}`,
        method: "PATCH",
        body: JSON.stringify(props),
      });

      if (!response.ok) {
        const error = await response.json();

        if (error.message) {
          throw new Error(error.message);
        } else {
          throw new Error("Failed to edit job dates.");
        }
      }

      return response.json();
    },
  });
};
