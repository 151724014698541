import { FC } from "react";

type Props = {
  name: string;
  required?: boolean;
  hidden?: string;
};

const MobileTextArea: FC<Props> = ({ name, required, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-[130px] flex-col border-b pt-[10px]">
      <span className="px-5 text-[11px] font-semibold uppercase text-[#636366]">
        {name}
      </span>
      <textarea
        name={name}
        disabled
        className="h-full resize-none border-none px-5 py-0 text-base font-normal text-black placeholder:font-normal placeholder:text-[#c7c7cb]"
        placeholder={required ? "Required" : "Optional"}
      ></textarea>
    </div>
  );
};

export { MobileTextArea };
