import { Button } from "@/UI/Button";
import { SmallLoadingSpinner } from "@/UI/Loading/SmallLoadingSpinner";
import { v4 as uuid } from "uuid";
import { useStreamTemplate } from "@/lib/react-query/mutationHooks/useStreamTemplate";
import { useFormBuilderStoreSelectors } from "@/lib/zustand/formBuilderStore";
import {
  ArrowPathIcon,
  DevicePhoneMobileIcon,
  TableCellsIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../Auth/AuthWrapper";
import { TextArea } from "@/UI/TextArea";
import {
  fieldToJSX,
  processGeneratedFormTemplate,
} from "@/utils/helpers/forms/formHelpers";
import { classNames } from "@/utils/helpers/classNames";
import { ErrorsTab } from "./ErrorsTab";
import { MobileField } from "@/types/forms/general";

const previewJSON = (IOSLayout: string) => {
  try {
    const fieldsJSON = JSON.parse(IOSLayout) as { fields: MobileField[] };
    const mappedFields = fieldsJSON.fields.map(fieldToJSX);
    return mappedFields;
  } catch (error) {
    return (
      <div className="flex h-[300px] w-full items-center justify-center">
        <p className="text-xl font-bold">Invalid JSON</p>
      </div>
    );
  }
};

const IOSLayoutHeader = () => (
  <div className="mb-6 ml-8 flex items-center">
    <DevicePhoneMobileIcon className="h-6 w-6 text-gray-500" />
    <h2 className="text-2xl font-semibold">IOS Layout</h2>
  </div>
);
export const IOSLayoutStep = () => {
  const { accountId } = useAuthContext();
  const [generating, setGenerating] = useState<boolean>(false);
  const [toggleMode, setToggleMode] = useState<"JSON" | "Errors">("JSON");
  const { startStream, responses, isStreaming, reset, stopStream } =
    useStreamTemplate();
  const divisionId = useFormBuilderStoreSelectors.use.divisionId();
  const formName = useFormBuilderStoreSelectors.use.formName();

  const formType = useFormBuilderStoreSelectors.use.AIFormType();
  const dataModel = useFormBuilderStoreSelectors.use.dataModel();
  const generatedFormTemplate =
    useFormBuilderStoreSelectors.use.generatedFormTemplate();
  const setGeneratedFormTemplate =
    useFormBuilderStoreSelectors.use.setGeneratedFormTemplate();
  const formDescription = useFormBuilderStoreSelectors.use.formDescription();
  const IOSLayout = useFormBuilderStoreSelectors.use.IOSLayout();
  const setIOSLayout = useFormBuilderStoreSelectors.use.setIOSLayout();
  const setWebLayout = useFormBuilderStoreSelectors.use.setWebLayout();

  const handleGenerateDataModel = async () => {
    if (!dataModel || !formType) return;
    setGeneratedFormTemplate(undefined);
    reset();
    setGenerating(true);
    startStream({ formType, dataModel });
  };

  // Try to parse the content as JSON for prettier display
  useEffect(() => {
    if (isStreaming || !responses) return;
    try {
      const jsonData = JSON.parse(responses);
      const processedFormTemplate = processGeneratedFormTemplate({
        accountId,
        description: formDescription,
        divisionId,
        name: formName,
        jsonData,
      });
      if (!processedFormTemplate) {
        throw new Error("Invalid generated form template");
      }
      setIOSLayout(
        JSON.stringify(
          { fields: processedFormTemplate.fields, alerts: [] },
          null,
          2
        )
      );
      setGeneratedFormTemplate(processedFormTemplate);
      setGenerating(false);
      toast.success("Form Template Generated");
    } catch (error) {
      toast.error("Unable to parse JSON, please try again");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStreaming]);

  if (generating) {
    return (
      <div>
        <IOSLayoutHeader />
        <div className=" w-full">
          <p className="mb-8 text-center text-gray-600 ">
            This may take a minute...
          </p>
          <div className="bg-card text-card-foreground w-full rounded-lg border shadow-sm">
            <div className="p-4  pt-0">
              <div className="bg-muted/50 h-[400px] overflow-y-auto rounded-md p-4 font-mono">
                {responses ? (
                  <div className="whitespace-pre-wrap break-words">
                    {responses}
                  </div>
                ) : (
                  <div className="text-muted-foreground flex h-full items-center justify-center">
                    {isStreaming ? (
                      <div className="flex items-center gap-2">
                        <SmallLoadingSpinner />
                        <span>Receiving stream...</span>
                      </div>
                    ) : (
                      <span>Stream content will appear here</span>
                    )}
                  </div>
                )}
              </div>
              {isStreaming && (
                <div className="text-muted-foreground mt-2 flex items-center gap-2 text-sm">
                  <div className="relative h-2 w-2">
                    <div className="absolute h-2 w-2 animate-ping rounded-full bg-green-500"></div>
                    <div className="absolute h-2 w-2 rounded-full bg-green-500"></div>
                  </div>
                  <span>Live streaming</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              stopStream();
              setGeneratedFormTemplate(undefined);
              setGenerating(false);
            }}
            variant="primary"
            label="Re-analyze Form"
            disabled={!formType}
            className="my-4 rounded-full"
            icon={<ArrowPathIcon className="h-6 w-6" />}
          />
        </div>
      </div>
    );
  }
  if (!generatedFormTemplate) {
    return (
      <div>
        <IOSLayoutHeader />
        {dataModel && formType ? (
          <div className="flex flex-col items-center space-y-6">
            <TableCellsIcon className="text-primary mr-2 h-10 w-10" />
            <div className="flex content-center justify-center">
              <p className="mb-6 mr-2  text-gray-500 ">Data Model Length: </p>
              <p className="font-medium">{dataModel.length}</p>
            </div>
            <div className="flex content-center justify-center">
              <p className="mb-6 mr-2  text-gray-500 ">Form Type</p>
              <p className="font-medium">{formType}</p>
            </div>
            <Button
              onClick={handleGenerateDataModel}
              variant="primary"
              label="Generate Form Template"
              className="mr-2 rounded-full"
              icon={<ArrowPathIcon className="h-6 w-6" />}
            />
          </div>
        ) : (
          <div className="my-8 flex flex-col items-center space-y-8 text-gray-600">
            <p>
              Generate a data model in the previous step and select a form type
              to continue.
            </p>
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <IOSLayoutHeader />
      <div className="my-4 flex w-full justify-between ">
        <div className="rounded-md border">
          <button
            className={classNames(
              "h-[38px] w-[94px] rounded-md border-l border-palette-tertiaryBlack",
              toggleMode === "JSON" ? "border-r bg-palette-companyGreen" : ""
            )}
            onClick={() => {
              setToggleMode("JSON");
            }}
          >
            <p className="text-sm font-medium">JSON</p>
          </button>

          <button
            className={classNames(
              "h-[38px] w-[94px] rounded-md border-palette-tertiaryBlack",
              toggleMode === "Errors" ? "border-x bg-palette-companyGreen" : ""
            )}
            onClick={() => {
              setToggleMode("Errors");
            }}
          >
            <p className="text-sm font-medium">Errors</p>
          </button>
        </div>
      </div>
      <div className="flex min-h-[600px] space-x-4">
        {toggleMode === "JSON" ? (
          <div className="relative w-full rounded-md">
            <TextArea
              textareaStyles="!resize-none !h-full"
              containerClassNames="!h-[calc(100%-24px)]"
              value={IOSLayout}
              onChange={(ev) => {
                setIOSLayout(ev.target.value ?? "");
              }}
            />
            <button
              className="absolute right-48 top-5 rounded-full border bg-amber-100 px-4 py-1 text-sm"
              onClick={() => {
                const newUuid = uuid();
                navigator.clipboard
                  .writeText(newUuid)
                  .then(() => {
                    toast.success(`New UUID copied to clipboard`);
                  })
                  .catch(() => {
                    toast.error("Something went wrong");
                  });
              }}
            >
              UUID
            </button>
            <button
              className="absolute right-24 top-5 rounded-full border bg-slate-200 px-4 py-1 text-sm"
              onClick={() => {
                if (!IOSLayout) return;
                try {
                  const parsedIOSLayout = JSON.parse(IOSLayout);
                  setIOSLayout(JSON.stringify(parsedIOSLayout, null, 2));
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              Beautify
            </button>
            <button
              className="absolute right-5 top-5 rounded-full border bg-red-200 px-4 py-1 text-sm"
              onClick={() => {
                setIOSLayout(
                  JSON.stringify(
                    { fields: generatedFormTemplate.fields, alerts: [] },
                    null,
                    2
                  )
                );
              }}
            >
              Reset
            </button>
          </div>
        ) : (
          <ErrorsTab type={"IOS"} />
        )}

        <div className="w-full rounded-md ">
          <div className="w-full basis-1/2 overflow-y-auto rounded-md border border-palette-tertiaryBlack bg-white">
            {IOSLayout && previewJSON(IOSLayout)}
          </div>
        </div>
      </div>
      <div className="my-4 flex justify-end">
        <Button
          onClick={() => {
            setGeneratedFormTemplate(null);
            setIOSLayout(undefined);
            setWebLayout(undefined);
          }}
          variant="primary"
          label="Erase Form and start again"
          className="mr-2 rounded-full"
          icon={<ArrowPathIcon className="h-6 w-6" />}
        />
      </div>
    </div>
  );
};
