/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment AlertFields on GqlFormTemplateAlert {\n    action\n    actionButton\n    cancelButton\n    hasTextField\n    keyboard\n    message\n    textField\n    textFieldPrompt\n    textFieldInputId\n    title\n  }\n": types.AlertFieldsFragmentDoc,
    "\n  fragment FieldAttributes on GqlFormTemplateFieldAttribute {\n    addRowIf\n    analytics\n    autocapitalize\n    buttonStyle\n    contentType\n    hidden\n    keyboardType\n    required\n    rowLimit\n    tagText\n    validate\n    visibleIf\n    readOnly\n    checkbox\n    style\n    divider\n    layout\n    alignment\n    extendedText\n    checkboxInputId\n    maxImages\n    mapping\n    startTimeId\n    endTimeId\n    notesId\n    taskTotalId\n    marginX\n    marginY\n    marginBottom\n    hideTitle\n    nowrap\n    titleTextSize\n    valueTextSize\n    valueTextWeight\n    title\n    endText\n    nullDash\n    startId\n    endId\n    outId\n    addButton\n    options\n    edit\n    editTitle\n    adderFieldIds\n    hiddenIf\n    discountsId\n    surchargeId\n    taxId\n    radioOptions\n    name\n    text\n    action\n    showRequiredUser\n    customItems\n    dayCounterId\n    alertMessage\n    alertTitle\n    minuteIncrements\n    prompt\n    textSize\n    bannerTitles\n    marginTop\n    path\n    source\n    isFormCreatedDate\n  }\n": types.FieldAttributesFragmentDoc,
    "\n  fragment FieldDataBinding on GqlFormTemplateFieldDatabinding {\n    expression\n    expressionVersion\n    onSelect\n    autoSelect\n  }\n": types.FieldDataBindingFragmentDoc,
    "\n  fragment FormFieldProperties on GqlFormTemplateField {\n    id\n    name\n    action\n    attributes {\n      ...FieldAttributes\n    }\n    databinding {\n      ...FieldDataBinding\n    }\n    fieldType\n    text\n    type\n    columns\n    source\n    path\n    columnStart\n    columnEnd\n    # ruleHooks {\n    #   ...RuleHooks\n    # }\n  }\n": types.FormFieldPropertiesFragmentDoc,
    "\n  fragment FormType on GqlFormType {\n    slug\n    displayName\n    iconUrl\n    bgColor\n    iconType\n  }\n": types.FormTypeFragmentDoc,
    "\n  fragment FormField on GqlFormTemplateField {\n    ...FormFieldProperties\n    fields {\n      ...FormFieldProperties\n    }\n  }\n": types.FormFieldFragmentDoc,
    "\n  fragment FormLayoutField on GqlFormTemplateLayoutFields {\n    type\n    columns\n    attributes {\n      ...FieldAttributes\n    }\n    fields {\n      ...FormFieldProperties\n    }\n  }\n": types.FormLayoutFieldFragmentDoc,
    "\n  fragment ParameterFields on GqlFormTemplateParameters {\n    forDate\n  }\n": types.ParameterFieldsFragmentDoc,
    "\n  fragment SubFormFields on GqlSubFormTemplate {\n    id\n    name\n    alerts {\n      ...AlertFields\n    }\n    fields {\n      ...FormField\n    }\n  }\n": types.SubFormFieldsFragmentDoc,
    "\n  mutation EditFormTemplate(\n    $updateFormTemplateId: ID!\n    $input: GqlUpdateFormTemplateInput!\n  ) {\n    updateFormTemplate(id: $updateFormTemplateId, input: $input) {\n      message\n      success\n      code\n    }\n  }\n": types.EditFormTemplateDocument,
    "\n  mutation CreateFormTemplate($input: GqlFormTemplateInput!) {\n    createFormTemplate(input: $input) {\n      message\n      success\n    }\n  }\n": types.CreateFormTemplateDocument,
    "\n  mutation CreateFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {\n    createFormTemplateQuickLink(input: $input) {\n      formId\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n      displayName\n      ordinal\n      createdAt\n      updatedAt\n      accountId\n      divisionIds\n      branchIds\n    }\n  }\n": types.CreateFormTemplateQuickLinkDocument,
    "\n  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {\n    createPriceBookItem(input: $input) {\n      success\n    }\n  }\n": types.CreatePriceBookItemDocument,
    "\n  mutation DeleteFormTemplate($deleteFormTemplateId: ID!) {\n    deleteFormTemplate(id: $deleteFormTemplateId) {\n      message\n      success\n      code\n    }\n  }\n": types.DeleteFormTemplateDocument,
    "\n  mutation DeleteFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {\n    deleteFormTemplateQuickLink(input: $input)\n  }\n": types.DeleteFormTemplateQuickLinkDocument,
    "\n  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {\n    deletePriceBookItem(id: $deletePriceBookItemId) {\n      success\n    }\n  }\n": types.DeletePriceBookItemDocument,
    "\n  mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {\n    createFormPDFConfig(input: $input) {\n      code\n      success\n      message\n    }\n  }\n": types.CreateFormPdfConfigDocument,
    "\n  mutation CreateJob($input: CreateJobInput!) {\n    createJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.CreateJobDocument,
    "\n  mutation UpdateJob($input: UpdateJobInput!) {\n    updateJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.UpdateJobDocument,
    "\n  mutation UpdatePriceBookItem($input: UpdatePriceBookItemInput!) {\n    updatePriceBookItem(input: $input) {\n      code\n      success\n      message\n      priceBookItem {\n        id\n        accountId\n        divisionIds\n        branchIds\n        createdAt\n        updatedAt\n        name\n        description\n        rate\n        unit\n        defaultValue\n        notes\n        category\n        status\n        active\n      }\n    }\n  }\n": types.UpdatePriceBookItemDocument,
    "\n  mutation UpdateFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {\n    updateFormTemplateQuickLink(input: $input) {\n      formId\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n      displayName\n      ordinal\n      createdAt\n      updatedAt\n      accountId\n      divisionIds\n      branchIds\n    }\n  }\n": types.UpdateFormTemplateQuickLinkDocument,
    "\n  mutation UpdateFormTypeAccount(\n    $slug: String!\n    $accountId: String!\n    $input: UpdateFormTypeAccountInput!\n  ) {\n    updateFormTypeAccount(slug: $slug, accountId: $accountId, input: $input) {\n      displayName\n      accountId\n      divisionIds\n      branchIds\n      createdAt\n      updatedAt\n      iconUrl\n      bgColor\n      iconType\n      ordinal\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n    }\n  }\n": types.UpdateFormTypeAccountDocument,
    "\n  query Colors($filters: ListColorsInput!) {\n    colors(filters: $filters) {\n      id\n      accountId\n      name\n      code\n      createdAt\n      updatedAt\n    }\n  }\n": types.ColorsDocument,
    "\n  query FormPDFConfig($formId: ID!) {\n    formPDFConfig(formId: $formId) {\n      cssTemplate\n      hbsTemplate\n      fields {\n        fieldId\n        format\n        label\n        fields {\n          fieldId\n          format\n          label\n        }\n      }\n    }\n  }\n": types.FormPdfConfigDocument,
    "\n  query FormTemplate($formTemplateId: ID!) {\n    formTemplate(id: $formTemplateId) {\n      id\n      accountId\n      createdAt\n      divisionId\n      formTypeSlug\n      flagForReview\n      formVersion\n      fields {\n        ...FormField\n      }\n\n      alerts {\n        ...AlertFields\n      }\n\n      subForms {\n        ...SubFormFields\n      }\n\n      layouts {\n        android {\n          fields {\n            ...FormField\n          }\n          alerts {\n            ...AlertFields\n          }\n          subforms {\n            ...SubFormFields\n          }\n        }\n        web {\n          downloadName\n          fields {\n            ...FormLayoutField\n          }\n        }\n      }\n\n      name\n      submitted\n      updatedAt\n      logicRules {\n        name\n        rule\n      }\n\n      renderEngineVersion\n      templateVersion\n\n      parameters {\n        forDate\n      }\n    }\n  }\n": types.FormTemplateDocument,
    "\n  query FormTemplateHomePage($accountId: ID!) {\n    formTemplateHomePage(accountId: $accountId) {\n      quickLinks {\n        formId\n        formType {\n          slug\n          displayName\n          description\n          createdAt\n          updatedAt\n          iconUrl\n          bgColor\n          iconType\n          ordinal\n          isGlobal\n        }\n        displayName\n        ordinal\n        createdAt\n        updatedAt\n        accountId\n        divisionIds\n        branchIds\n      }\n      templateTypeOrder {\n        formIds\n        formType {\n          slug\n          displayName\n          description\n          createdAt\n          updatedAt\n          iconUrl\n          bgColor\n          iconType\n          ordinal\n          isGlobal\n        }\n      }\n    }\n  }\n": types.FormTemplateHomePageDocument,
    "\n  query FormTemplates($accountId: ID!) {\n    formTemplates(accountId: $accountId) {\n      accountId\n      divisionId\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n      fields {\n        type\n        text\n        path\n        name\n        id\n        databinding {\n          expression\n        }\n        fields {\n          type\n          text\n          path\n          name\n          id\n          databinding {\n            expression\n          }\n        }\n      }\n      id\n      formVersion\n      name\n      subForms {\n        id\n        name\n        fields {\n          type\n          text\n          path\n          name\n          id\n          databinding {\n            expression\n          }\n          fields {\n            type\n            text\n            path\n            name\n            id\n            databinding {\n              expression\n            }\n          }\n        }\n      }\n      layouts {\n        web {\n          downloadName\n          fields {\n            type\n            columns\n            fields {\n              type\n              text\n              source\n              path\n              name\n              id\n              columnStart\n              attributes {\n                hideTitle\n                marginBottom\n                marginX\n                marginY\n                nowrap\n                titleTextSize\n                valueTextSize\n                valueTextWeight\n                endText\n                nullDash\n                startId\n                endId\n                outId\n                addButton\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.FormTemplatesDocument,
    "\n  query Job($jobId: ID!) {\n    job(id: $jobId) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.JobDocument,
    "\n  query Jobs($filters: ListJobsInput!) {\n    jobs(filters: $filters) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.JobsDocument,
    "\n  query PriceBookItems($filters: ListPriceBookItemsInput!) {\n    priceBookItems(filters: $filters) {\n      id\n      accountId\n      divisionIds\n      branchIds\n      createdAt\n      updatedAt\n      name\n      description\n      rate\n      unit\n      defaultValue\n      notes\n      category\n      status\n      active\n    }\n  }\n": types.PriceBookItemsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AlertFields on GqlFormTemplateAlert {\n    action\n    actionButton\n    cancelButton\n    hasTextField\n    keyboard\n    message\n    textField\n    textFieldPrompt\n    textFieldInputId\n    title\n  }\n"): typeof import('./graphql').AlertFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FieldAttributes on GqlFormTemplateFieldAttribute {\n    addRowIf\n    analytics\n    autocapitalize\n    buttonStyle\n    contentType\n    hidden\n    keyboardType\n    required\n    rowLimit\n    tagText\n    validate\n    visibleIf\n    readOnly\n    checkbox\n    style\n    divider\n    layout\n    alignment\n    extendedText\n    checkboxInputId\n    maxImages\n    mapping\n    startTimeId\n    endTimeId\n    notesId\n    taskTotalId\n    marginX\n    marginY\n    marginBottom\n    hideTitle\n    nowrap\n    titleTextSize\n    valueTextSize\n    valueTextWeight\n    title\n    endText\n    nullDash\n    startId\n    endId\n    outId\n    addButton\n    options\n    edit\n    editTitle\n    adderFieldIds\n    hiddenIf\n    discountsId\n    surchargeId\n    taxId\n    radioOptions\n    name\n    text\n    action\n    showRequiredUser\n    customItems\n    dayCounterId\n    alertMessage\n    alertTitle\n    minuteIncrements\n    prompt\n    textSize\n    bannerTitles\n    marginTop\n    path\n    source\n    isFormCreatedDate\n  }\n"): typeof import('./graphql').FieldAttributesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FieldDataBinding on GqlFormTemplateFieldDatabinding {\n    expression\n    expressionVersion\n    onSelect\n    autoSelect\n  }\n"): typeof import('./graphql').FieldDataBindingFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FormFieldProperties on GqlFormTemplateField {\n    id\n    name\n    action\n    attributes {\n      ...FieldAttributes\n    }\n    databinding {\n      ...FieldDataBinding\n    }\n    fieldType\n    text\n    type\n    columns\n    source\n    path\n    columnStart\n    columnEnd\n    # ruleHooks {\n    #   ...RuleHooks\n    # }\n  }\n"): typeof import('./graphql').FormFieldPropertiesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FormType on GqlFormType {\n    slug\n    displayName\n    iconUrl\n    bgColor\n    iconType\n  }\n"): typeof import('./graphql').FormTypeFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FormField on GqlFormTemplateField {\n    ...FormFieldProperties\n    fields {\n      ...FormFieldProperties\n    }\n  }\n"): typeof import('./graphql').FormFieldFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FormLayoutField on GqlFormTemplateLayoutFields {\n    type\n    columns\n    attributes {\n      ...FieldAttributes\n    }\n    fields {\n      ...FormFieldProperties\n    }\n  }\n"): typeof import('./graphql').FormLayoutFieldFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ParameterFields on GqlFormTemplateParameters {\n    forDate\n  }\n"): typeof import('./graphql').ParameterFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SubFormFields on GqlSubFormTemplate {\n    id\n    name\n    alerts {\n      ...AlertFields\n    }\n    fields {\n      ...FormField\n    }\n  }\n"): typeof import('./graphql').SubFormFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditFormTemplate(\n    $updateFormTemplateId: ID!\n    $input: GqlUpdateFormTemplateInput!\n  ) {\n    updateFormTemplate(id: $updateFormTemplateId, input: $input) {\n      message\n      success\n      code\n    }\n  }\n"): typeof import('./graphql').EditFormTemplateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFormTemplate($input: GqlFormTemplateInput!) {\n    createFormTemplate(input: $input) {\n      message\n      success\n    }\n  }\n"): typeof import('./graphql').CreateFormTemplateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {\n    createFormTemplateQuickLink(input: $input) {\n      formId\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n      displayName\n      ordinal\n      createdAt\n      updatedAt\n      accountId\n      divisionIds\n      branchIds\n    }\n  }\n"): typeof import('./graphql').CreateFormTemplateQuickLinkDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {\n    createPriceBookItem(input: $input) {\n      success\n    }\n  }\n"): typeof import('./graphql').CreatePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteFormTemplate($deleteFormTemplateId: ID!) {\n    deleteFormTemplate(id: $deleteFormTemplateId) {\n      message\n      success\n      code\n    }\n  }\n"): typeof import('./graphql').DeleteFormTemplateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {\n    deleteFormTemplateQuickLink(input: $input)\n  }\n"): typeof import('./graphql').DeleteFormTemplateQuickLinkDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {\n    deletePriceBookItem(id: $deletePriceBookItemId) {\n      success\n    }\n  }\n"): typeof import('./graphql').DeletePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {\n    createFormPDFConfig(input: $input) {\n      code\n      success\n      message\n    }\n  }\n"): typeof import('./graphql').CreateFormPdfConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateJob($input: CreateJobInput!) {\n    createJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').CreateJobDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateJob($input: UpdateJobInput!) {\n    updateJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').UpdateJobDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePriceBookItem($input: UpdatePriceBookItemInput!) {\n    updatePriceBookItem(input: $input) {\n      code\n      success\n      message\n      priceBookItem {\n        id\n        accountId\n        divisionIds\n        branchIds\n        createdAt\n        updatedAt\n        name\n        description\n        rate\n        unit\n        defaultValue\n        notes\n        category\n        status\n        active\n      }\n    }\n  }\n"): typeof import('./graphql').UpdatePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateFormTemplateQuickLink($input: GqlFormTemplateQuickLinkInput!) {\n    updateFormTemplateQuickLink(input: $input) {\n      formId\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n      displayName\n      ordinal\n      createdAt\n      updatedAt\n      accountId\n      divisionIds\n      branchIds\n    }\n  }\n"): typeof import('./graphql').UpdateFormTemplateQuickLinkDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateFormTypeAccount(\n    $slug: String!\n    $accountId: String!\n    $input: UpdateFormTypeAccountInput!\n  ) {\n    updateFormTypeAccount(slug: $slug, accountId: $accountId, input: $input) {\n      displayName\n      accountId\n      divisionIds\n      branchIds\n      createdAt\n      updatedAt\n      iconUrl\n      bgColor\n      iconType\n      ordinal\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n    }\n  }\n"): typeof import('./graphql').UpdateFormTypeAccountDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Colors($filters: ListColorsInput!) {\n    colors(filters: $filters) {\n      id\n      accountId\n      name\n      code\n      createdAt\n      updatedAt\n    }\n  }\n"): typeof import('./graphql').ColorsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FormPDFConfig($formId: ID!) {\n    formPDFConfig(formId: $formId) {\n      cssTemplate\n      hbsTemplate\n      fields {\n        fieldId\n        format\n        label\n        fields {\n          fieldId\n          format\n          label\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').FormPdfConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FormTemplate($formTemplateId: ID!) {\n    formTemplate(id: $formTemplateId) {\n      id\n      accountId\n      createdAt\n      divisionId\n      formTypeSlug\n      flagForReview\n      formVersion\n      fields {\n        ...FormField\n      }\n\n      alerts {\n        ...AlertFields\n      }\n\n      subForms {\n        ...SubFormFields\n      }\n\n      layouts {\n        android {\n          fields {\n            ...FormField\n          }\n          alerts {\n            ...AlertFields\n          }\n          subforms {\n            ...SubFormFields\n          }\n        }\n        web {\n          downloadName\n          fields {\n            ...FormLayoutField\n          }\n        }\n      }\n\n      name\n      submitted\n      updatedAt\n      logicRules {\n        name\n        rule\n      }\n\n      renderEngineVersion\n      templateVersion\n\n      parameters {\n        forDate\n      }\n    }\n  }\n"): typeof import('./graphql').FormTemplateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FormTemplateHomePage($accountId: ID!) {\n    formTemplateHomePage(accountId: $accountId) {\n      quickLinks {\n        formId\n        formType {\n          slug\n          displayName\n          description\n          createdAt\n          updatedAt\n          iconUrl\n          bgColor\n          iconType\n          ordinal\n          isGlobal\n        }\n        displayName\n        ordinal\n        createdAt\n        updatedAt\n        accountId\n        divisionIds\n        branchIds\n      }\n      templateTypeOrder {\n        formIds\n        formType {\n          slug\n          displayName\n          description\n          createdAt\n          updatedAt\n          iconUrl\n          bgColor\n          iconType\n          ordinal\n          isGlobal\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').FormTemplateHomePageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FormTemplates($accountId: ID!) {\n    formTemplates(accountId: $accountId) {\n      accountId\n      divisionId\n      formType {\n        slug\n        displayName\n        description\n        createdAt\n        updatedAt\n        iconUrl\n        bgColor\n        iconType\n        ordinal\n        isGlobal\n      }\n      fields {\n        type\n        text\n        path\n        name\n        id\n        databinding {\n          expression\n        }\n        fields {\n          type\n          text\n          path\n          name\n          id\n          databinding {\n            expression\n          }\n        }\n      }\n      id\n      formVersion\n      name\n      subForms {\n        id\n        name\n        fields {\n          type\n          text\n          path\n          name\n          id\n          databinding {\n            expression\n          }\n          fields {\n            type\n            text\n            path\n            name\n            id\n            databinding {\n              expression\n            }\n          }\n        }\n      }\n      layouts {\n        web {\n          downloadName\n          fields {\n            type\n            columns\n            fields {\n              type\n              text\n              source\n              path\n              name\n              id\n              columnStart\n              attributes {\n                hideTitle\n                marginBottom\n                marginX\n                marginY\n                nowrap\n                titleTextSize\n                valueTextSize\n                valueTextWeight\n                endText\n                nullDash\n                startId\n                endId\n                outId\n                addButton\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').FormTemplatesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Job($jobId: ID!) {\n    job(id: $jobId) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): typeof import('./graphql').JobDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Jobs($filters: ListJobsInput!) {\n    jobs(filters: $filters) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): typeof import('./graphql').JobsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PriceBookItems($filters: ListPriceBookItemsInput!) {\n    priceBookItems(filters: $filters) {\n      id\n      accountId\n      divisionIds\n      branchIds\n      createdAt\n      updatedAt\n      name\n      description\n      rate\n      unit\n      defaultValue\n      notes\n      category\n      status\n      active\n    }\n  }\n"): typeof import('./graphql').PriceBookItemsDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
