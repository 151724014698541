import { FC } from "react";

type Props = {
  name: string;
  hidden?: string;
};

const MobileMinutePicker: FC<Props> = ({ name, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-[60px] flex-col justify-center border-b bg-white">
      <p className="px-5 text-[11px] font-semibold uppercase text-[#636366]">
        {name}
      </p>

      <div className="flex gap-x-1 px-5">
        <span className="text-base font-normal text-[#c7c7cb]">0</span>
        <span className="text-base font-normal text-[#7c7c80]">min</span>
      </div>
    </div>
  );
};

export { MobileMinutePicker };
