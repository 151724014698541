import { Alert } from "@/UI/Alert";
import { Button } from "@/UI/Button";
import { LoadingSpinner } from "@/UI/Loading";
import { Select } from "@/UI/Select";
import { useCreateFormGql } from "@/lib/react-query/mutationHooks/useCreateFormGql";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useFormBuilderStoreSelectors } from "@/lib/zustand/formBuilderStore";
import { FORM_TYPES } from "@/types/forms/general";
import { useAlert } from "@/utils/hooks/useAlert";
import { ArrowUpCircleIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SubmitInfoHeader = () => (
  <div className="mb-6 ml-8 flex items-center">
    <ArrowUpCircleIcon className="text-primary mr-2 h-6 w-6" />
    <h2 className="text-2xl font-semibold">Submit</h2>
  </div>
);
const formTypeOptions = Object.values(FORM_TYPES).map((type, index) => ({
  id: `${index}`,
  value: type,
}));
export const SubmitStep: React.FC = () => {
  const navigate = useNavigate();
  const createFormGql = useCreateFormGql();
  const { alertProps, closeAlert, setAlert } = useAlert();
  const formName = useFormBuilderStoreSelectors.use.formName();
  const generatedFormTemplate =
    useFormBuilderStoreSelectors.use.generatedFormTemplate();
  const formType = useFormBuilderStoreSelectors.use.formType();
  const setFormType = useFormBuilderStoreSelectors.use.setFormType();
  const IOSLayout = useFormBuilderStoreSelectors.use.IOSLayout();
  const webLayout = useFormBuilderStoreSelectors.use.webLayout();
  const divisionId = useFormBuilderStoreSelectors.use.divisionId();
  const { data: divisions } = useDivisions({
    options: { staleTime: 5 * 1000 * 60, gcTime: 5 * 1000 * 60 },
  });
  const divisionName = divisions?.data.find(
    (division) => division.id === divisionId
  )?.divisionName;
  const getIOSLayout = () => {
    if (!IOSLayout) return;
    try {
      return JSON.parse(IOSLayout);
    } catch (error) {
      return;
    }
  };
  const getWebLayout = () => {
    if (!webLayout) return;
    try {
      return JSON.parse(webLayout);
    } catch (error) {
      return;
    }
  };

  const getDescription = () => {
    const layout = getIOSLayout();
    if (!layout) return;
    return layout?.fields[0]?.text;
  };

  const isValid =
    !!formName &&
    !!formType &&
    !!divisionId &&
    getIOSLayout() &&
    getWebLayout() &&
    getDescription();

  const handleSave = () => {
    setAlert({
      body: "This action cannot be undone. Please ensure all client form renderers work with this new form upon save.",
      cancelButtonText: "Cancel",
      confirmButtonText: "Save",
      onCancel: () => {
        closeAlert();
      },
      onConfirm: async () => {
        closeAlert();
        const parsedIOSLayout = getIOSLayout();
        const parsedWebLayout = getWebLayout();
        if (!parsedIOSLayout || !parsedWebLayout) {
          toast.error("something went wrong");
          return;
        }
        const fullFormTemplate = {
          ...generatedFormTemplate,
          ...parsedIOSLayout,
          name: formName ?? `🤖 AI Generated ${formType}`,
          divisionIds: [divisionId],
          layouts: {
            web: {
              downloadName: "Download Name",
              fields: parsedWebLayout,
            },
          },
          formTypeSlug: formType,
          formVersion: "0.0.5",
          renderEngineVersion: "0.0.1",
          templateVersion: "0.0.1",
        };
        await createFormGql.mutateAsync(
          {
            input: fullFormTemplate,
          },
          {
            onSuccess: () => {
              toast.success("Form Created");
              navigate("/admin/forms");
            },
            onError: () => toast.error("Unable to create form."),
          }
        );
      },
      open: true,
      title: "Save Changes",
    });
  };

  const selectedFormType = formTypeOptions.find(
    (fType) => fType.value === formType
  );
  if (createFormGql.isPending) {
    return (
      <div>
        <SubmitInfoHeader />
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <SubmitInfoHeader />
      <div className="flex flex-col items-center">
        <div className="max-w-lg">
          <p className="mx-autom my-10  text-center text-sm text-gray-500 ">
            Please make sure there are no errors in the IOS or Web layouts as
            this will cause problems when rendering the real forms. Also make
            sure to check Ribbiot IOS to ensure that when the form is created in
            the account, the app is able to parse the new form.
          </p>
          <h3 className="mb-4 text-lg font-medium">Form Summary</h3>

          <div className="space-y-3">
            <div className="flex items-center justify-between border-b py-2">
              <span className="text-gray-600 dark:text-gray-400">Name</span>
              <span className="font-medium">
                {formName || (
                  <span className="text-theme-red-primary">
                    Please give a name
                  </span>
                )}
              </span>
            </div>
            <div className="flex items-center justify-between border-b py-2">
              <span className="text-gray-600 dark:text-gray-400">
                Description
              </span>
              <span className="font-medium">
                {getDescription() || (
                  <span className="text-theme-red-primary">
                    Please give a description
                  </span>
                )}
              </span>
            </div>
            <div className="flex items-center justify-between border-b py-2">
              <span className="text-gray-600 dark:text-gray-400">
                Form Type
              </span>
              <Select
                options={formTypeOptions}
                onChange={(fType) => setFormType(fType?.value as FORM_TYPES)}
                value={selectedFormType}
              />
            </div>
            <div className="flex items-center justify-between border-b py-2">
              <span className="text-gray-600 dark:text-gray-400">Division</span>
              <span className="font-medium">
                {divisionName ?? (
                  <span className="text-theme-red-primary">
                    Please Select Division
                  </span>
                )}
              </span>
            </div>
            <div className="flex items-center justify-between border-b py-2">
              <span className="text-gray-600 dark:text-gray-400">
                IOS Layout
              </span>
              <span className="font-medium">
                {getIOSLayout() ? "✅" : "❌"}
              </span>
            </div>
            <div className="flex items-center justify-between border-b py-2">
              <span className="text-gray-600 dark:text-gray-400">
                Web Layout
              </span>
              <span className="font-medium">
                {getWebLayout() ? "✅" : "❌"}
              </span>
            </div>
          </div>
          <div className="mt-20 flex">
            <Button
              className="mx-auto rounded-full"
              label="Save New Form"
              variant="primary"
              disabled={!isValid}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
      <Alert {...alertProps} />
    </div>
  );
};
