/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Branch } from "@/types/users/general";
import { branchFormDefaultValues } from "./helpers";
import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import { ControlledInput } from "@/UI/Input/ControlledInput";
import { FC, useEffect } from "react";
import { LoadingSpinner } from "@/UI/Loading";
import { PenIcon, TrashIcon } from "@/assets";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCreateBranch } from "@/lib/react-query/mutationHooks/useCreateBranch";
import { useEditBranch } from "@/lib/react-query/mutationHooks/useEditBranch";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BranchFormSchema,
  branchFormSchema,
  getBranchFormValues,
} from "@/utils/formDefinitions/branches/general";
import { useSidebarStoreSelectors } from "@/lib/zustand/sidebarStore";

type AdminBranchFormProps = {
  branchData: Branch | undefined;
  edit: boolean;
};

const AdminBranchForm: FC<AdminBranchFormProps> = ({ branchData, edit }) => {
  const editBranch = useEditBranch();
  const createBranch = useCreateBranch();

  const setUnsavedDataWarning =
    useSidebarStoreSelectors.use.setUnsavedDataWarning();

  const { handleSubmit, formState, setValue, setError, control, watch, reset } =
    useForm<BranchFormSchema>({
      defaultValues: {
        ...branchFormDefaultValues,
      },
      ...(edit && {
        values: getBranchFormValues(branchData),
      }),
      resolver: zodResolver(branchFormSchema),
    });

  const logo = watch("logo") || "";
  const quoteCover = watch("quoteCover") || "";

  const handleLogoToShow = (img: string) => {
    if (img.startsWith("data")) return img;

    const hasQuestionMark = img.includes("?");

    if (hasQuestionMark) {
      const parsed = img.split("?")[0];
      return `${parsed}?etag=${new Date().getTime().toString()}`;
    }

    return `${img}?etag=${new Date().getTime().toString()}`;
  };

  const handleImageToSend = (img: string, type: "quoteCover" | "logo") => {
    if (type === "logo" && branchData?.logo === img) return undefined;

    if (type === "quoteCover" && branchData?.quoteCover === img) {
      return undefined;
    }

    const hasComma = img.includes(",");

    if (hasComma) return img.split(",")[1];

    return img;
  };

  const onSubmit: SubmitHandler<BranchFormSchema> = (data) => {
    if (data) {
      if (!data.logo) {
        setError("logo", { message: "Required" });
        return;
      }

      if (edit && branchData) {
        editBranch.mutate(
          {
            body: {
              ...data,
              logo: handleImageToSend(logo, "logo"),
              quoteCover: data.quoteCover
                ? handleImageToSend(quoteCover, "quoteCover")
                : undefined,
            },
            branchId: branchData?.id,
          },
          {
            onSuccess: () => {
              toast.success("Branch edited");
              reset(data);
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }
        );
      } else {
        createBranch.mutate(
          {
            body: {
              ...data,
              logo: handleImageToSend(logo, "logo") as string,
              quoteCover: data.quoteCover
                ? handleImageToSend(quoteCover, "quoteCover")
                : undefined,
            },
          },
          {
            onSuccess: () => {
              toast.success("Branch created");
              reset(data);
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }
        );
      }
    }
  };

  const selectImg = (field: "quoteCover" | "logo") => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        setValue(field, event?.target?.result?.toString() || "", {
          shouldDirty: true,
        });
      };
      reader.readAsDataURL(selection!);
    });

    input.click();
    input.remove();
  };

  const removeImg = (field: "quoteCover" | "logo") => {
    setValue(field, undefined, { shouldDirty: true });
  };

  const loadingState = editBranch.isPending || createBranch.isPending;

  useEffect(() => {
    setUnsavedDataWarning(formState.isDirty);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isDirty]);

  return (
    <>
      {loadingState && (
        <div className="relative z-50 translate-y-[100px]">
          <LoadingSpinner />
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit, (errors) =>
          console.error("errors", errors)
        )}
      >
        <div className="flex gap-x-5">
          <div className="flex w-max items-center gap-x-2">
            <div>
              <h1 className="text-sm font-medium text-[#575757]">
                Branch Image
              </h1>

              <div
                className={classNames(
                  "group relative h-56 w-56 rounded-xl bg-theme-blue-primary",
                  formState.errors.logo ? "border border-red-600" : ""
                )}
              >
                {logo && (
                  <img
                    className="h-56 w-56 rounded-xl border-2 bg-contain bg-no-repeat"
                    src={handleLogoToShow(logo)}
                    alt="Branch logo"
                  />
                )}

                <div className="absolute right-2 top-2 flex items-center gap-x-1 opacity-0 group-hover:opacity-100">
                  <button
                    className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-green-primary"
                    type="button"
                    onClick={() => selectImg("logo")}
                  >
                    <PenIcon fill="white" />
                  </button>

                  <button
                    className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-red-primary"
                    type="button"
                    onClick={() => removeImg("logo")}
                  >
                    <TrashIcon />
                  </button>
                </div>

                {formState.errors.logo && (
                  <p className="absolute bottom-[-22px] text-[12px] font-medium text-theme-red-primary">
                    {formState.errors.logo.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <h1 className="text-sm font-medium text-[#575757]">
                Quote Cover Image
              </h1>

              <div
                className={classNames(
                  "group relative h-56 w-56 rounded-xl bg-theme-blue-primary"
                )}
              >
                {quoteCover && (
                  <img
                    className="h-56 w-56 rounded-xl border-2 bg-contain bg-no-repeat"
                    src={handleLogoToShow(quoteCover)}
                    alt="Quote Cover"
                  />
                )}

                <div className="absolute right-2 top-2 flex items-center gap-x-1 opacity-0 group-hover:opacity-100">
                  <button
                    className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-green-primary"
                    type="button"
                    onClick={() => selectImg("quoteCover")}
                  >
                    <PenIcon fill="white" />
                  </button>

                  <button
                    className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-red-primary"
                    type="button"
                    onClick={() => removeImg("quoteCover")}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="grid w-full grid-cols-2 gap-x-5 gap-y-2">
            <ControlledInput
              control={control}
              name="name"
              label="Branch Name"
              disabled={edit}
            />

            <ControlledInput
              control={control}
              name="divisionId"
              label="Division ID"
            />

            <ControlledInput label="Email" control={control} name="email" />

            <ControlledInput
              label="Phone Number"
              control={control}
              name="phoneNumber"
            />

            <ControlledInput label="Address" control={control} name="address" />

            <ControlledInput label="City" control={control} name="city" />

            <ControlledInput label="State" control={control} name="state" />

            <ControlledInput
              label="Postal Code"
              control={control}
              name="postalCode"
            />
          </div>
        </div>

        <div className={classNames("mt-6 flex flex-row justify-end gap-5")}>
          <Button
            label="Save Branch"
            type="submit"
            variant="primary"
            disabled={!formState.isDirty}
            className="rounded-md"
          />
        </div>
      </form>
    </>
  );
};

export { AdminBranchForm };
