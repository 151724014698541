import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { graphql } from "@/lib/graphql";
import { FormTemplateQuery } from "@/lib/graphql/graphql";

interface UseFormTemplateQueryParams {
  formTemplateId: string;
  options?: Partial<UseQueryOptions<{ data: FormTemplateQuery }>>;
}

const FormTemplate = graphql(`
  query FormTemplate($formTemplateId: ID!) {
    formTemplate(id: $formTemplateId) {
      id
      accountId
      createdAt
      divisionId
      formTypeSlug
      flagForReview
      formVersion
      fields {
        ...FormField
      }

      alerts {
        ...AlertFields
      }

      subForms {
        ...SubFormFields
      }

      layouts {
        android {
          fields {
            ...FormField
          }
          alerts {
            ...AlertFields
          }
          subforms {
            ...SubFormFields
          }
        }
        web {
          downloadName
          fields {
            ...FormLayoutField
          }
        }
      }

      name
      submitted
      updatedAt
      logicRules {
        name
        rule
      }

      renderEngineVersion
      templateVersion

      parameters {
        forDate
      }
    }
  }
`);

export const useFormTemplate = (params?: UseFormTemplateQueryParams) => {
  const { graphqlRequest } = useAuthContext();
  return useQuery({
    queryKey: [QUERY_KEYS.FORM_TEMPLATE, { formId: params?.formTemplateId }],
    queryFn: async () =>
      graphqlRequest(FormTemplate, {
        formTemplateId: params?.formTemplateId ?? "",
      }),
    enabled: !!params?.formTemplateId,
    ...(params?.options && { ...params.options }),
  });
};
