import { classNames } from "@/utils/helpers/classNames";
import * as React from "react";

const DataModelTable = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full ">
    <table
      ref={ref}
      className={classNames("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
));
DataModelTable.displayName = "DataModelTable";

const DataModelTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={classNames("[&_tr]:border-b", className)}
    {...props}
  />
));
DataModelTableHeader.displayName = "DataModelTableHeader";

const DataModelTableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={classNames("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
DataModelTableBody.displayName = "DataModelTableBody";

const DataModelTableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={classNames(
      "bg-primary text-primary-foreground font-medium",
      className
    )}
    {...props}
  />
));
DataModelTableFooter.displayName = "DataModelTableFooter";

const DataModelTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={classNames(
      "hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors",
      className
    )}
    {...props}
  />
));
DataModelTableRow.displayName = "DataModelTableRow";

const DataModelTableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={classNames(
      "text-muted-foreground h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props}
  />
));
DataModelTableHead.displayName = "DataModelTableHead";

const DataModelTableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={classNames(
      "p-4 align-middle [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props}
  />
));
DataModelTableCell.displayName = "DataModelTableCell";

const DataModelTableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={classNames("text-muted-foreground mt-4 text-sm", className)}
    {...props}
  />
));
DataModelTableCaption.displayName = "DataModelTableCaption";

export {
  DataModelTable,
  DataModelTableHeader,
  DataModelTableBody,
  DataModelTableFooter,
  DataModelTableHead,
  DataModelTableRow,
  DataModelTableCell,
  DataModelTableCaption,
};
