import { FC } from "react";

type Props = {
  name: string;
  checkbox?: string;
  hidden?: string;
};

// NOTES BANNER

const MobileNotesBanner: FC<Props> = ({ name, checkbox, hidden = "false" }) => {
  if (hidden === "true") return <></>;

  return (
    <div className="flex h-8 w-full items-center justify-between border-b bg-[#E0F5ED] px-5">
      <div className="flex items-center gap-x-[6px]">
        <span className="text-base font-semibold">
          {name}
          {checkbox && ":"}
        </span>

        {checkbox && (
          <div className="flex items-center gap-x-1">
            <input
              className="h-[18px] w-[18px] rounded-sm border-2"
              disabled
              type="checkbox"
            ></input>
            <span className="text-sm font-medium">{checkbox}</span>
          </div>
        )}
      </div>

      <span className="text-sm font-semibold text-[#35B888]">+ Add Notes</span>
    </div>
  );
};

export { MobileNotesBanner };
