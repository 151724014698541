import { LocalStorageCache, useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Button } from "../Button";
import { ProfileIcon } from "@/assets";

interface FallbackProps {
  error?: string;
  signOut?: boolean;
}

const Fallback: FC<FallbackProps> = ({ error, signOut = true }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  useEffect(() => {
    amplitude.track(AMPLITUDE_EVENTS.ERROR_PAGE_VIEWED, {
      url: location.href,
      error_message: error,
    });
  }, [error]);

  if (typeof error === "object") {
    if (
      (error as { message: string }).message
        .toLowerCase()
        .includes("failed to fetch dynamically imported module:")
    ) {
      return <></>;
    }
  }

  return (
    <div className="m-auto flex min-h-screen w-9/12 items-center justify-center py-16">
      <div className="overflow-hidden bg-white pb-8 shadow sm:rounded-lg">
        <div className="border-t border-gray-200 p-10 pt-8 text-center">
          {signOut && (
            <button
              className="ml-auto flex justify-center gap-2 rounded-full border border-black px-3 py-1"
              type="button"
              onClick={() => {
                const localStorageCache = new LocalStorageCache();
                localStorageCache.remove("login");
                localStorage.clear();
                sessionStorage.clear();
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
            >
              <ProfileIcon />
              Sign Out
            </button>
          )}
          <img
            width={200}
            height={200}
            className="m-auto"
            src="/error-frog.png"
            alt="The page you are looking for does not exist. It might have
            been moved or deleted."
          />
          <h1 className="py-8 text-6xl font-medium">An Error Occured</h1>
          <p className="px-12 pb-8 text-2xl font-medium">
            Click the link to navigate home.
          </p>
          <a
            href="/"
            onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }}
            className="rounded-full bg-theme-green-primary px-10 py-2 font-medium text-white"
          >
            HOME
          </a>
          <Button
            label="Support"
            variant="quarternary"
            type="submit"
            className="mx-auto mt-4 block w-32"
            onClick={() => {
              window.open("https://ribbiot.zendesk.com/hc/en-us", "_blank");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { Fallback };
